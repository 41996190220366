import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {Button} from 'react-bootstrap';


class SignupForm extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {};
    }

    /*
    Signup Complete
    Signup Failed but Logged in
    Logged in
    Error
     */

    render() {
        return (
            <div>
                {this.getContent()}
                <Button bsStyle="primary" onClick={this.props.goBack}>Zurück</Button>
            </div>
        );
    }


    getContent = () => {
        if (this.props.response === "error") {
            return (
                <div>
                    <h1>Das hätte nicht passieren dürfen!</h1>
                    <p>Es tut mir Leid, es ist ein unbekannter Fehler aufgetreten. Bitte probiere es nochmal oder schreibe mir eine Mail an
                        {process.env.REACT_APP_SUPPORT_EMAIL} und ich helfe Dir, so schnell es geht.</p>
                </div>
            )
        } else if (this.props.response === "userDoesNotExist") {
            return (
                <div>
                    <h1>Anmeldung nicht erfolgreich</h1>
                    <p>
                        Leider ist Deine E-Mail Adresse nicht registriert oder es gibt ein Problem mit der Datenbank. Bist Du sicher, dass du dich schon registriert hast? Bitte probiere erneut, dich einzuloggen oder registriere dich.
                    </p>
                    <p>
                        Wenn Du weiterhin Probleme bei der Anmeldung hast, wende dich bitte an {process.env.REACT_APP_SUPPORT_EMAIL}, ich melde mich bei Dir so schnell wie möglich.
                    </p>
                </div>
            )
        }else if (this.props.response === "signupSuccessful") {
            return (
                <div>
                    <h1>Anmeldung erfolgreich</h1>
                    <p>
                        Vielen Dank für Deine Anmeldung! Du hast eine Email mit einem Bestätigungslink
                        bekommen. Bitte klicke auf den Link darin, Du wirst dann automatisch eingeloggt. <br/>
                        Solltest du keine Mail bekommen haben, überprüfe bitte deinen Spam-Filter.
                    </p>
                </div>
            )
        }else{
            return (
                <div>
                    <h1>Das hätte nicht passieren dürfen!</h1>
                    <p>Es tut mir Leid, es ist ein unbekannter Fehler aufgetreten. Bitte probiere es nochmal oder schreibe mir eine Mail an
                        {process.env.REACT_APP_SUPPORT_EMAIL} und ich helfe Dir, so schnell es geht.</p>
                </div>
            )
        }
    }

}

export default withRouter(SignupForm);