import React, {Component} from 'react';
import './App.css';
import {Route, withRouter} from 'react-router-dom';
import Cookies from 'universal-cookie';
//import ReactGA from 'react-ga';


import LandingPage from "./components/LandingPage";
import Verify from "./components/Verify";
import ResetPassword from "./components/ResetPassword";
import MainApp from "./components/MainApp";
import Feedback from "./components/Feedback";
import Impressum from "./components/Impressum";


class App extends Component {
    constructor(props) {
        super(props);


        let cookies = new Cookies();
        if (cookies.get("userId") && typeof (cookies.get("userId") === "number") && cookies.get("authenticationToken") && cookies.get("authenticationToken").length > 3) {
            this.state = {
                loginData: {
                    currentlyLoggedIn: true,
                    userId: cookies.get("userId"),
                    authenticationToken: cookies.get("authenticationToken")
                }
            };
        } else {
            this.state = {
                loginData: {
                    currentlyLoggedIn: false,
                    userId: null,
                    authenticationToken: ""
                }
            };
        }
    }

    render() {
        /*
                <Route path={"/login"} exact render={() => {
                    <LoginPage loginData={this.state.loginData} loginMethod={this.loginWithEmailAndPassword} />

                }}/>
                */

        return (
            <div id={"root"}>
                <Route path="/" exact render={() => {
                    return (
                        <LandingPage loginData={this.state.loginData} loginMethod={this.loginWithEmailAndPassword}/>);
                }
                }/>

                <Route path="/signup" exact render={() => {
                    return (
                        <LandingPage loginData={this.state.loginData} loginMethod={this.loginWithEmailAndPassword}/>);
                }
                }/>

                <Route path="/login" exact render={() => {
                    return (
                        <LandingPage loginData={this.state.loginData} loginMethod={this.loginWithEmailAndPassword}/>);
                }
                }/>

                <Route path="/verify/:id/:verificationToken" render={() => {
                    return (<Verify verificationMethod={this.verifyWithToken}/>);
                }}/>

                <Route path="/resetPassword/:id/:resetPasswordToken" render={() => {
                    return (<ResetPassword updatePasswordAndLogin={this.updatePasswordAndLogin}/>)
                }}/>

                <Route path="/chart" render={() => {
                    return (<div>
                        <MainApp loginData={this.state.loginData} logout={this.logout}/>
                    </div>)
                }
                }/>

                <Route path="/feedback" render={() => {
                    return (<Feedback/>)
                }}/>

                <Route path="/impressum" render={() => {
                    return (<Impressum/>)
                }}/>
            </div>
        )
    }

    componentDidMount() {
//Google Analytics automatically tracks page views.
        /*ReactGA.initialize('UA-131173791-1');
        ReactGA.set({ anonymizeIp: true });
        this.props.history.listen(location => ReactGA.pageview(location.pathname));*/
    }

    verifyWithToken = (userId, verificationToken) => {
        return new Promise((resolve, reject) => {
            let url = process.env.REACT_APP_API + "/verify";
            fetch(url, {
                method: 'post',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({userId: userId, verificationToken: verificationToken})
            })
                .then((resp) => resp.json())
                .then(result => {
                    //console.log("Response to signup request was: " + answer);
                    if (result.success === true && result.authenticationToken && result.authenticationToken.length > 3) {
                        let cookies = new Cookies();
                        cookies.set('userId', userId);
                        cookies.set('authenticationToken', result.authenticationToken);
                        this.setState({
                            loginData: {
                                currentlyLoggedIn: true,
                                userId: userId,
                                authenticationToken: result.authenticationToken
                            }
                        }, () => {
                            resolve()
                        })
                    } else {
                        this.setState({
                            loginData: {
                                currentlyLoggedIn: false,
                                userId: null,
                                authenticationToken: null
                            }
                        }, () => {
                            reject();
                        });
                    }
                })
                .catch((e) => {
                        this.setState({
                            loginData: {
                                currentlyLoggedIn: false,
                                userId: null,
                                authenticationToken: null
                            }
                        }, () => {
                            reject();
                        });
                    }
                );
        })
    };

    loginWithEmailAndPassword = (email, password) => {

        return new Promise((resolve, reject) => {

            let url = process.env.REACT_APP_API + "/login";
            fetch(url, {
                method: 'post',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: email,
                    password: password
                })
            })
                .then((resp) => resp.json())
                .then(result => {
                    if (result.success === true && result.authenticationToken.length > 3 && result.userId) {
                        /*ReactGA.event({
                            category: 'User',
                            action: 'Login with Password successful'
                        });*/

                        let cookies = new Cookies();
                        cookies.set('userId', result.userId);
                        cookies.set('authenticationToken', result.authenticationToken);
                        this.setState({
                            loginData: {
                                currentlyLoggedIn: true,
                                userId: result.userId,
                                authenticationToken: result.authenticationToken
                            }
                        }, () => {
                            resolve()
                        })
                    } else {
                        /*ReactGA.event({
                            category: 'User',
                            action: 'Login with Password failed'
                        });*/
                        this.setState({
                            loginData: {
                                currentlyLoggedIn: false,
                                userId: null,
                                authenticationToken: null
                            }
                        }, () => {
                            reject(result.error);
                        });
                    }
                })
                .catch((e) => {
                        /*ReactGA.event({
                            category: 'User',
                            action: 'Login with Password failed'
                        });*/
                        this.setState({
                            loginData: {
                                currentlyLoggedIn: false,
                                userId: null,
                                authenticationToken: null
                            }
                        }, () => {
                            reject();
                        });
                    }
                );
        })
    };

    logout = () => {
        return new Promise((resolve, reject) => {
            let cookies = new Cookies();
            cookies.remove('userId');
            cookies.remove('authenticationToken');

            let url = process.env.REACT_APP_API + "/logout";
            fetch(url, {
                method: 'post',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    userId: this.state.loginData.userId,
                    authenticationToken: this.state.loginData.authenticationToken
                })
            })
                .then((resp) => resp.json())
                .then(result => {
                    this.setState({
                        loginData: {
                            currentlyLoggedIn: false,
                            userId: null,
                            authenticationToken: ""
                        }
                    }, () => {

                        resolve();
                    })
                })
                .catch((e) => {
                    this.setState({
                        loginData: {
                            currentlyLoggedIn: false,
                            userId: null,
                            authenticationToken: ""
                        }
                    }, () => {

                        resolve();
                    })
                })


        })
    };

    updatePasswordAndLogin = (userId, resetPasswordToken, newPassword) => {
        return new Promise((resolve, reject) => {
            let url = process.env.REACT_APP_API + "/resetPassword";
            fetch(url, {
                method: 'post',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    userId: userId,
                    resetPasswordToken: resetPasswordToken,
                    newPassword: newPassword
                })
            })
                .then((resp) => resp.json())
                .then(result => {
                    //console.log("Response to signup request was: " + answer);
                    if (result.success === true && result.authenticationToken && result.authenticationToken.length > 3) {
                        let cookies = new Cookies();
                        cookies.set('userId', userId);
                        cookies.set('authenticationToken', result.authenticationToken);
                        this.setState({
                            loginData: {
                                currentlyLoggedIn: true,
                                userId: userId,
                                authenticationToken: result.authenticationToken
                            }
                        }, () => {
                            resolve()
                        })
                    } else {
                        this.setState({
                            loginData: {
                                currentlyLoggedIn: false,
                                userId: null,
                                authenticationToken: null
                            }
                        }, () => {
                            reject(result.error);
                        });
                    }
                })
                .catch((e) => {
                        this.setState({
                            loginData: {
                                currentlyLoggedIn: false,
                                userId: null,
                                authenticationToken: null
                            }
                        }, () => {
                            reject();
                        });
                    }
                );
        })
    };


}

export default withRouter(App);