import {Col, Row} from "react-bootstrap";
import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import BackgroundImage from "../img/LandingPageBG.jpg";
import BottomInformationRow from "./BottomInformationRow";

class Feedback extends Component {
    componentWillMount() {
        document.body.style.backgroundImage = `url(${BackgroundImage})`;
        document.body.style.backgroundSize = 'cover';
        document.body.style.height = '100%';
        // document.body.style.backgroundColor = "red";
    }

    componentWillUnmount() {
        //document.body.style.backgroundImage = null;
    }

    render() {
        return (
            <div>
                <div className="container-fluid fill main-container">
                    <Row className={"main-row align-items-center full-row"}>
                        <Col md={1}></Col>
                        <Col md={8} className={"left-col"} id={"landingPageLeftCol"}>
                            <div id="spacer"></div>
                            <h1>Klausurio soll schöner werden!</h1>
                            <p>Ich möchte eine Webapp bauen, die du gerne benutzt. Wenn etwas nicht klappt oder noch
                                besser gemacht werden kann, sag gerne Bescheid. </p>
                            <p>Über ein Lob freue ich mich natürlich auch.</p>
                            <p>
                                Jan Kothe<br/>
                                {process.env.REACT_APP_FEEDBACK_EMAIL}<br/>
                                </p>
                            <BottomInformationRow/>
                        </Col>
                        <Col md={3} className={"right-col"} id={"landingPageRightCol"}>
                            <div id={"rightColContent"}>

                            </div>
                        </Col>
                    </Row>

                </div>

            </div>

        );
    }
}

export default withRouter(Feedback);