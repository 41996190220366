/**
 * Created by jankothe on 19.06.18.
 * */

import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {Form, OverlayTrigger, Popover} from "react-bootstrap";
import '../css/EditMistakeTagRelationsCheckbox.css';

class EditMistakeTagRelationsCheckbox extends Component {
    addMistakeTagRelation = () => {
        this.props.updateNewMistakeTagRelations(this.props.mistakeTag.id, true);
    };

    deleteMistakeTagRelation = () => {
        this.props.updateNewMistakeTagRelations(this.props.mistakeTag.id, false);
    };

    getCheckboxDescription = () => {
        return (
            <OverlayTrigger
                overlay={<Popover className={"mistakeTagPopover"} id={this.props.mistakeTag.id}
                                  title={this.props.mistakeTag.name}>{this.props.mistakeTag.tooltipText}</Popover>}
                placement="bottom"
                delayShow={500}
                delayHide={50}
            >
                <div>
                    {this.props.mistakeTag.name}
                </div>
            </OverlayTrigger>
        );
    }

    render() {
        if (this.props.checked === true) { //key={`custom-inline-checkbox-` + this.props.mistakeTag.id}
            return (

                <Form.Check
                    inline
                    type="checkbox"
                    id={`custom-inline-checkbox-` + this.props.mistakeTag.id}

                >
                    <Form.Check.Input
                        checked={this.props.checked}
                        onChange={this.deleteMistakeTagRelation}
                    />
                    {this.getCheckboxDescription()}
                </Form.Check>


            )
        } else {//key={`custom-inline-checkbox-` + this.props.mistakeTag.id}
            return (
                <Form.Check
                    inline
                    type="checkbox"
                    id={`custom-inline-checkbox-` + this.props.mistakeTag.id}
                >
                    <Form.Check.Input
                        checked={this.props.checked}
                        onChange={this.addMistakeTagRelation}
                    />
                    {this.getCheckboxDescription()}
                </Form.Check>
            )
        }

    }
}

export default withRouter(EditMistakeTagRelationsCheckbox);