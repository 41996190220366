/**
 * Created by jankothe on 19.06.18.
 */
import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';

import '../css/LandingPage.css';

import {Button, Col, Row} from "react-bootstrap";
import BackgroundImage from '../img/LandingPageBG.jpg';


class Verify extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            response: ""
        }
    }

    componentWillMount() {
        document.body.style.backgroundImage = `url(${BackgroundImage})`;
        document.body.style.backgroundSize = 'cover';
        document.body.style.height = '100%';
        // document.body.style.backgroundColor = "red";
    }

    componentWillUnmount() {
        //document.body.style.backgroundImage = null;
    }

    componentDidMount() {
        let params = this.props.match.params;
        let userId = params.id;
        let verificationToken = params.verificationToken;

        this.props.verificationMethod(userId, verificationToken)
            .then(() => {
                this.setState({
                    response: "success"
                });
            })
            .catch(() => {
                this.setState({
                    response: "error"
                })
            })
    }

    backButtonClicked = () => {
        this.props.history.push("/");
    };

    getLeftColContent = () => {
        if (this.state.response === "") {
            return (<div>
                <h1>Bitte warte, Du wirst eingeloggt.</h1>
                <div className="loader"></div>
            </div>)
        } else if (this.state.response === "error") {
            return (
                <div id={"spacer"}>
                    <h1>Das tut mir leid!</h1>
                    <p>Leider scheint etwas mit Deinem Login Link nicht zu stimmen. Beachte: Die Links funktionieren nur
                        für 30 Minuten und nur einmal. </p>
                    <p>Gehe einfach zurück und ich schicke dir einen neuen.</p>
                    <Button bsStyle="primary" onClick={this.backButtonClicked}>Zurück zum Login</Button><br/>

                </div>);
        } else if (this.state.response === "success") {
            this.props.history.push("/chart");
        }
    };

    render() {
        return (
            <div className="container-fluid fill main-container">
                <Row className={"main-row align-items-center full-row"}>

                    <Col md={5} className={"left-col"} id={"landingPageLeftCol"}>
                        {this.getLeftColContent()}
                    </Col>
                    <Col md={7} className={"right-col"} id={"landingPageRightCol"}>
                        <div id={"rightColContent"}>
                            <h1>Verbesserst Du dich?</h1>
                            <h4>Lass Deine Exü Ergebnisse analysieren - Steigen Deine Noten? Wie gut bist Du im Schnitt?
                                Wie oft liegst Du über dem Durchschnitt?</h4>
                            <h4>Deine Ergebnisse sind und bleiben natürlich nur für Dich sichtbar!</h4>
                        </div>
                    </Col>
                </Row>
            </div>

        );
    }

}

export default withRouter(Verify);
