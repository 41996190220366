/**
 * Created by jankothe on 19.06.18.
 */
import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {Card, Row, Col, Container} from "react-bootstrap";

import MainChart from "./MainChart";
import MainDataTable from "./MainDataTable";
import EditExamView from "./EditExamView";
import MistakeAnalysis from "./MistakeAnalysis";
import CustomNavbar from "./CustomNavbar";
import CustomFooterBar from "./CustomFooterBar"
import StatisticsPanel from "./StatisticsPanel";
import ShowTrendWarningWindow from "./ShowTrendWarningWindow";
//import ReactGA from 'react-ga';

class MainApp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            /*activeSubject & subject: Possible Values:
                             AL = All Exams
                            ZR = Zivilrecht
                            OR = Öffentliches Recht
                            SR = Strafrecht*/
            activeSubject: "AL",

            /*comparisonActive: Decides whether exam views show comparison to other students
            - Currently only 1/0: On/Off, is an Int intentionally to make it possible to include further modes later
            */
            activeComparisonMode: 0,
            exams: [],
            exus: [],
            mistakeTags: [],
            mistakeTagCategories: [],
            user: {},

            editModeActive: false,
            exuIdToEdit: null,
            showTrendWarningWindowActive: false
        };

        if (this.props.loginData.currentlyLoggedIn !== true) {
            this.props.logout()
                .then(() => {
                    this.props.history.push("/");
                });
        }
    }

    componentDidMount() {
        if (this.props.loginData.currentlyLoggedIn) {
            this.getUser()
                .then(() => this.getExamData())
                .then(() => this.getExUs())
                .then(() => this.getMistakeTags())
                .then(() => this.getMistakeTagCategories())
                .catch((e) => {

                })
        }
        document.body.style.backgroundImage = null;
    }

    componentWillMount() {
        document.body.style.backgroundImage = null;
    }

    render() {
        let panelStyle = "default";
        return (
            <div>
                <CustomNavbar onLogoutClicked={this.logoutClicked}/>
                <div className="App, container-fluid">

                    {this.getEditView()}
                    {this.getMistakeAnalysisView()}
                    {this.getShowTrendWarningWindow()}

                    <Container fluid>
                        <Row className={"align-items-center full-row"}>
                            <Col md={8} className={"left-col"}>
                                <Card bg={panelStyle}>
                                    <Card.Body>
                                        <Card.Title>Exü Ergebnisse in der Übersicht</Card.Title>
                                        <MainChart activeSubject={this.state.activeSubject}
                                                   activeComparisonMode={this.state.activeComparisonMode}
                                                   exams={this.state.exams}
                                                   exus={this.state.exus}
                                                   showTrend={this.state.user.showTrend}
                                                   showAverage={this.state.user.showAverage}
                                        />
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md={4} className={"right-col"}>
                                <Card bg={panelStyle}>
                                    <Card.Body>
                                        <Card.Title>Statistiken</Card.Title>
                                        <StatisticsPanel exams={this.state.exams}
                                                         exus={this.state.exus}
                                                         showTrendButtonClicked={this.handleShowTrendButtonClick}
                                                         showTrend={this.state.user.showTrend}
                                                         showAverageButtonClicked = {this.handleShowAverageButtonClick}
                                                         showAverage = {this.state.user.showAverage}
                                                         showMistakeAnalysisClicked={this.triggerMistakeAnalysis}
                                        />
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} className={""}>
                                <Card bg={panelStyle}>
                                    <Card.Body>
                                        <Card.Title>Geschriebene Exüs</Card.Title>
                                        <MainDataTable exams={this.state.exams} exus={this.state.exus}
                                                       handleEditButtonClickOnExu={this.triggerExamEdit}/>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <br />
                <CustomFooterBar/>
            </div>
        );
    }

    handleConfirmShowTrendButtonClick = (e) => {
        this.setShowTrendWarningWindowActivityStatus(false);
        this.updateShowTrendStatus(true);
    };

    handleCancelShowTrendButtonClick = (e) => {
        this.setShowTrendWarningWindowActivityStatus(false);
        this.updateShowTrendStatus(false);
    };

    getShowTrendWarningWindow = () => {
        if (this.state.showTrendWarningWindowActive === true) {
            return <ShowTrendWarningWindow handleConfirmButtonClick={this.handleConfirmShowTrendButtonClick}
                                           handleCancelButtonClick={this.handleCancelShowTrendButtonClick}/>
        } else {
            return "";
        }
    };

    handleShowTrendButtonClick = (e) => {
        if (this.state.user.showTrend === true) {
            this.updateShowTrendStatus(false);
        } else {
            this.setShowTrendWarningWindowActivityStatus(true);
        }
    };

    handleShowAverageButtonClick = (e) => {
        if (this.state.user.showAverage === true) {
            this.updateShowAverageStatus(false);
        } else {
            this.updateShowAverageStatus(true);
        }
    };

    setShowTrendWarningWindowActivityStatus = (newStatus) => {
        this.setState({
            showTrendWarningWindowActive: newStatus
        });
    };

    updateShowTrendStatus = (newShowTrendStatus) => {
        this.setState({
            user: {
                ...this.state.user,
                showTrend: newShowTrendStatus
            }
        }, () => {
            console.log("Show Trend Status in State = " + this.state.user.showTrend);
        });

        this.updateShowTrendStatusInBackend(newShowTrendStatus);
    };

    updateShowAverageStatus = (newShowAverageStatus) => {
        this.setState({
            user: {
                ...this.state.user,
                showAverage: newShowAverageStatus
            }
        }, () => {
            console.log("Show Average Status in State = " + this.state.user.showAverage);
        });

        this.updateShowAverageStatusInBackend(newShowAverageStatus);
    };

    updateShowTrendStatusInBackend = (newShowTrendStatus) => {
        let url = process.env.REACT_APP_API + "/user";
        if (newShowTrendStatus != null && typeof (newShowTrendStatus) === "boolean") {
            fetch(url, {
                method: 'PATCH',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    userId: this.props.loginData.userId,
                    authenticationToken: this.props.loginData.authenticationToken,
                    showTrend: newShowTrendStatus
                })
            })
                .then((resp) => resp.json())
                .then(result => {
                    console.log("Saved showTrendStatus: " + result.success);
                })
                .catch((e) => {
                        console.log("Error saving new Trend Status to Backend");
                        this.setState({});
                    }
                )
        }
    };

    updateShowAverageStatusInBackend = (newShowAverageStatus) => {
        let url = process.env.REACT_APP_API + "/user";
        if (newShowAverageStatus != null && typeof (newShowAverageStatus) === "boolean") {
            fetch(url, {
                method: 'PATCH',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    userId: this.props.loginData.userId,
                    authenticationToken: this.props.loginData.authenticationToken,
                    showAverage: newShowAverageStatus
                })
            })
                .then((resp) => resp.json())
                .then(result => {
                    console.log("Saved showAverageStatus: " + result.success);
                })
                .catch((e) => {
                        console.log("Error saving new Average Status to Backend");
                        this.setState({});
                    }
                )
        }
    };

    logoutClicked = () => {
        /*ReactGA.event({
            category: 'User',
            action: 'Logged out'
        });*/
        this.props.logout()
            .then(() => {
                this.props.history.push("/");
            });
    };

    triggerExamEdit = (exuId) => {
        /*ReactGA.event({
            category: 'User',
            action: 'Edit Exam view opened'
        });*/
        this.setState({
            editModeActive: !this.state.editModeActive,
            exuIdToEdit: exuId
        })
    };

    hideEditView = () => {
        /*ReactGA.event({
            category: 'User',
            action: 'Edit Exam view closed'
        });*/
        this.setState({
            editModeActive: false
        })
    };

    triggerMistakeAnalysis = () => {
        /*ReactGA.event({
            category: 'User',
            action: 'Mistake Analysis opened'
        });*/
        this.setState({
            mistakeAnalysisActive: true
        })
    };

    hideMistakeAnalysis = () => {
        /*ReactGA.event({
            category: 'User',
            action: 'Mistake Analysis closed'
        });*/
        this.setState({
            mistakeAnalysisActive: false
        })
    };

    getEditView = () => {
        if (this.state.editModeActive) {
            let exuToEdit = null;
            let examToEdit = null;


            this.state.exus.forEach((element) => {
                if (element.id === this.state.exuIdToEdit) {
                    exuToEdit = element;
                    this.state.exams.forEach((element) => {
                        if (element.exuId === this.state.exuIdToEdit) {
                            examToEdit = element;
                        }
                    });
                }
            });

            return (
                <EditExamView loginData={this.props.loginData}
                              exam={examToEdit}
                              exu={exuToEdit}
                              mistakeTags={this.state.mistakeTags}
                              mistakeTagCategories={this.state.mistakeTagCategories}
                              handleCancelClick={this.hideEditView}
                              hideEditView={this.hideEditView}
                              updateExam={this.updateExam}
                              deleteExam={this.deleteExam}
                              createExam={this.createExam}
                              triggerExamReload={this.getExamData}
                              triggerMistakeTagReload={this.getMistakeTags}
                />
            );
        } else {
            return "";
        }

    };

    getMistakeAnalysisView = () => {
        if (this.state.mistakeAnalysisActive) {

            return (
                <MistakeAnalysis
                              handleDoneClick={this.hideMistakeAnalysis}
                              mistakeTags={this.state.mistakeTags}
                              mistakeTagCategories={this.state.mistakeTagCategories}
                              exams={this.state.exams}
                              exus={this.state.exus}
                />
            );
        } else {
            return "";
        }

    };

    getMistakeTags = () => {
        return new Promise((resolve, reject) => {
            let url = process.env.REACT_APP_API + "/mistakeTag?";
            let params = {
                userId: this.props.loginData.userId,
                authenticationToken: this.props.loginData.authenticationToken
            };
            let esc = encodeURIComponent;
            let query = Object.keys(params)
                .map(k => esc(k) + '=' + esc(params[k]))
                .join('&');
            url += query;

            fetch(url, {
                method: 'get',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                }
            })
                .then((resp) => resp.json())
                .then(result => {
                    //debugger;
                    if (result.success === true && result.mistakeTags && result.mistakeTags.length > 0) {
                        let sortedMistakeTags = result.mistakeTags.sort(function (a, b) {
                            let nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
                            if (nameA < nameB) //sort string ascending
                                return -1;
                            if (nameA > nameB)
                                return 1;
                            return 0; //default return value (no sorting)
                        });

                        this.setState({
                            mistakeTags: sortedMistakeTags
                        }, () => {
                            resolve();
                        })
                    } else {
                        this.setState({
                            mistakeTags: []
                        }, () => {
                            resolve();
                        })
                    }

                })
                .catch((e) => {
                        reject(e);
                        this.setState({
                            errorMessage: e
                        });
                    }
                )
        });
    };

    getMistakeTagCategories = () => {
        return new Promise((resolve, reject) => {
            let url = process.env.REACT_APP_API + "/mistakeTagCategory?";
            let params = {
                userId: this.props.loginData.userId,
                authenticationToken: this.props.loginData.authenticationToken
            };
            let esc = encodeURIComponent;
            let query = Object.keys(params)
                .map(k => esc(k) + '=' + esc(params[k]))
                .join('&');
            url += query;

            fetch(url, {
                method: 'get',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                }
            })
                .then((resp) => resp.json())
                .then(result => {

                    if (result.success === true && result.mistakeTagCategories && result.mistakeTagCategories.length > 0) {
                        /*let sortedMistakeTags = result.mistakeTags.sort(function (a, b) {
                            let nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
                            if (nameA < nameB) //sort string ascending
                                return -1;
                            if (nameA > nameB)
                                return 1;
                            return 0; //default return value (no sorting)
                        });*/

                        this.setState({
                            mistakeTagCategories: result.mistakeTagCategories
                        }, () => {
                            resolve();
                        })
                    } else {
                        this.setState({
                            mistakeTagCategories: []
                        }, () => {
                            resolve();
                        })
                    }

                })
                .catch((e) => {
                        reject(e);
                        this.setState({
                            errorMessage: e
                        });
                    }
                )
        });
    };

    getExUs = () => {
        return new Promise((resolve, reject) => {
            let url = process.env.REACT_APP_API + "/exu?";
            let params = {
                userId: this.props.loginData.userId,
                authenticationToken: this.props.loginData.authenticationToken
            };
            let esc = encodeURIComponent;
            let query = Object.keys(params)
                .map(k => esc(k) + '=' + esc(params[k]))
                .join('&');
            url += query;

            fetch(url, {
                method: 'get',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                }
            })
                .then((resp) => resp.json())
                .then(result => {

                    if (result.success === true && result.exus && result.exus.length > 0) {
                        let sortedExus = result.exus.sort(function (a, b) {
                            // Turn your strings into dates, and then subtract them
                            // to get a value that is either negative, positive, or zero.
                            return new Date(a.date) - new Date(b.date);
                        });
                        this.setState({
                            exus: sortedExus
                        }, () => {
                            resolve();
                        })
                    } else {
                        this.setState({
                            exus: []
                        }, () => {
                            resolve();
                        })
                    }

                })
                .catch((e) => {
                        reject(e);
                        this.setState({
                            errorMessage: e
                        });
                    }
                )
        });
    };

    getExamData = () => {
        return new Promise((resolve, reject) => {
            let url = process.env.REACT_APP_API + "/exam?";
            let params = {
                userId: this.props.loginData.userId,
                authenticationToken: this.props.loginData.authenticationToken
            };
            let esc = encodeURIComponent;
            let query = Object.keys(params)
                .map(k => esc(k) + '=' + esc(params[k]))
                .join('&');
            url += query;

            fetch(url, {
                method: 'get',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                }
            })
                .then((resp) => resp.json())
                .then(result => {
                    //console.log("Response to signup request was: " + answer);
                    if (result.success === true && result.examData) {

                        this.setState({
                            exams: result.examData
                        }, () => {
                            resolve();
                        })
                    } else {
                        resolve();
                        /*this.props.handleLoginStatusChange({
                            newStatus: "loggedOut"
                        });*/
                    }

                })
                .catch((e) => {
                        reject(e);
                        this.setState({
                            errorMessage: e
                        });
                    }
                )
        })
    };

    getUser = () => {
        return new Promise((resolve, reject) => {
            if (this.props.loginData.currentlyLoggedIn) {
                let url = process.env.REACT_APP_API + "/user/" + this.props.loginData.userId + "?authenticationToken=" + this.props.loginData.authenticationToken;
                fetch(url, {
                    method: 'get',
                    headers: {
                        'Accept': 'application/json, text/plain, */*',
                        'Content-Type': 'application/json'
                    }
                })
                    .then((resp) => resp.json())
                    .then(result => {
                        if (result.success === true && result.user) {
                            this.setState({
                                user: result.user
                            }, () => {
                                resolve()
                            })
                        }
                    })
                    .catch((e) => {
                        reject(e);
                    })
            }
        })
    };

    //All of these don't work right now because database was seeded and the user currently used in Chrome has been removed from the DB
    updateExam = (exam) => {
        return new Promise((resolve, reject) => {
            /*ReactGA.event({
                category: 'Exam',
                action: 'Exam updated'
            });*/
            this.updateExamInBackend(exam)
                .then(resolve);
        })
    };

    updateExamInBackend = (exam) => {
        //debugger;
        return new Promise((resolve, reject) => {
            //UPDATE Exam with correct data & existing exam
            let url = process.env.REACT_APP_API + "/exam";
            if (exam != null && exam.id != null && typeof (exam.grade) === "number" && exam.grade > 0 && exam.grade <= 18) {
                fetch(url, {
                    method: 'PATCH',
                    headers: {
                        'Accept': 'application/json, text/plain, */*',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        userId: this.props.loginData.userId,
                        authenticationToken: this.props.loginData.authenticationToken,
                        examId: exam.id,
                        grade: exam.grade
                    })
                })
                    .then((resp) => resp.json())
                    .then(result => {
                        //debugger;
                        if(result.success === true && parseInt(result.examId) > 0){
                            //debugger;
                            resolve(parseInt(result.examId));
                        }else{
                            reject(result.error || 7);
                        }
                    })
                    .catch((e) => {
                            //console.log("Error fetching ressource: " + e);
                            this.setState({
                                errorMessage: e
                            }, () => {
                                reject(e);
                            });
                        }
                    )
            }
        });
    };

    deleteExam = (exam) => {
        return new Promise((resolve, reject) => {
            /*ReactGA.event({
                category: 'Exam',
                action: 'Exam deleted'
            });*/
            this.deleteExamInBackend(exam)
                .then(resolve);
        });
    };

    deleteExamInBackend = (exam) => {
        return new Promise((resolve, reject) => {
            //DELETE Exam with 0 as new grade
            let url = process.env.REACT_APP_API + "/exam";
            if (exam != null && typeof (exam.id) === "number") {
                fetch(url, {
                    method: 'DELETE',
                    headers: {
                        'Accept': 'application/json, text/plain, */*',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        userId: this.props.loginData.userId,
                        authenticationToken: this.props.loginData.authenticationToken,
                        examId: exam.id
                    })
                })
                    .then((resp) => resp.json())
                    .then(result => {
                        resolve(result)
                    })
                    .catch((e) => {
                            console.log("Error fetching ressource: " + e);
                            this.setState({
                                errorMessage: e
                            }, () => {
                                reject(e)
                            });
                        }
                    )
            }
        });
    };

    createExam = (exam) => {
        return new Promise((resolve, reject) => {
            /*ReactGA.event({
                category: 'Exam',
                action: 'Exam created'
            });*/
            this.createExamInBackend(exam)
                .then((examId) => {
                    resolve(examId);
                })
                .catch((e) => {
                    reject(e);
                })
        });
    };

    createExamInBackend = (exam) => {
        return new Promise((resolve, reject) => {
            //Then sync state to API
            let url = process.env.REACT_APP_API + "/exam";

            if (exam != null && exam.exuId != null && typeof (exam.grade) === "number" && exam.grade > 0 && exam.grade < 18) {
                fetch(url, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json, text/plain, */*',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        userId: this.props.loginData.userId,
                        authenticationToken: this.props.loginData.authenticationToken,
                        exuId: exam.exuId,
                        grade: exam.grade
                    })
                })
                    .then((resp) => resp.json())
                    .then(result => {
                        if(result.success === true && parseInt(result.examId) > 0){
                            //debugger;
                            resolve(parseInt(result.examId));
                        }else{
                            reject(result.error || 7);
                        }

                    })
                    .catch((e) => {
                            console.log("Error fetching ressource: " + e);
                            this.setState({
                                errorMessage: e
                            }, () => {
                                reject(e)
                            });
                        }
                    )
            }
        });
    };
}

export default withRouter(MainApp);