import {Col, Row} from "react-bootstrap";
import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import BackgroundImage from "../img/LandingPageBG.jpg";
import BottomInformationRow from "./BottomInformationRow";

class
Impressum extends Component {
    componentWillMount() {
        document.body.style.backgroundImage = `url(${BackgroundImage})`;
        document.body.style.backgroundSize = 'cover';
        document.body.style.height = '100%';
        // document.body.style.backgroundColor = "red";
    }

    componentWillUnmount() {
        //document.body.style.backgroundImage = null;
    }

    render() {
        return (
            <div>
                <div className="container-fluid fill main-container">
                    <Row className={"main-row align-items-center full-row"}>
                        <Col md={1}></Col>
                        <Col md={8} className={"left-col"} id={"landingPageLeftCol"}>
                            <div id="spacer"></div>
                            <h1>Impresssum</h1>
                            <p>Jan Willem Kothe<br />
                                Klausurio<br />
                                Wolffstraße 2A<br />
                                22525 Hamburg<br />
                                jan.kothe@klausurio.de
                            </p>
                            <p>
                                Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer
                                Verbraucherschlichtungsstelle teilzunehmen.
                            </p>
                                <BottomInformationRow/>
                        </Col>
                        <Col md={3} className={"right-col"} id={"landingPageRightCol"}>
                            <div id={"rightColContent"}>

                            </div>
                        </Col>
                    </Row>

                </div>

            </div>

        );
    }
}

export default withRouter(Impressum);