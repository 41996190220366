/**
 * Created by jankothe on 19.06.18.
 */
import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend} from 'recharts';
import regression from 'regression';



class MainChart extends Component {
    componentDidMount() {
        //document.body.style.backgroundImage = null;
    }

    componentWillMount(){
        //document.body.style.backgroundImage = null;
    }

    getChartDataWithTrendline = () => {
        let chartData = this.getChartData();
        let trendData = this.getTrendlineDataFromChartData(chartData);


        for(let i=0; i<chartData.length; i++){
            chartData[i].trend = trendData[i];
        }
        return chartData;
    };

    getChartData = () => {
        let chartData = [];
        this.props.exus.forEach((exu) => {
            let date = new Date(exu.date);
            let dateString = date.toLocaleDateString();
            let grade = null;

            this.props.exams.forEach((exam) => {
                if(exam.exuId === exu.id){
                    grade = exam.grade;
                }
            });

            chartData.push({
                name: dateString,
                Note: grade,
                ExuSchnitt: exu.averageGrade
            })
        });


        /*
       Datenformat:
       [
       {name:"20/07/2018", Note: 4, ExuSchnitt: 7}
       ]
         */
        return chartData;
    };

    getTrendlineDataFromChartData = (chartData) => {
        let regressionData = [];
        for(let i=0; i<chartData.length; i++){
            regressionData.push([i, chartData[i].Note])
        }

        //Sample data: [[0, 1], [32, 67], [12, 79]]
        let regressionResult = regression.linear(regressionData);
        let gradient = regressionResult.equation[0];
        let yIntercept = regressionResult.equation[1];

        let trendData = [];
        for(let i=0; i<chartData.length; i++){
            let trendFunctionResult = gradient*i + yIntercept;
            let result = 0;
            if(trendFunctionResult >18) {
                result = 18;
            }else if(trendFunctionResult<0){
                result = 0;
            }else{
                result = trendFunctionResult;
            }
            trendData[i]=result;
        }
        return trendData;
    };

    getTrendLine = () => {
        if(this.props.showTrend === true){
            return <Line type="monotone" dataKey="trend" stroke="#00FF00" activeDot={{r: 8}} connectNulls/>
        }else{
            return "";
        }
    };

    getAverageLine = () => {
        if(this.props.showAverage === true){
            return <Line type="monotone" dataKey="ExuSchnitt" stroke="#0000FF" activeDot={{r: 8}} connectNulls/>
        }else{
            return "";
        }
    };



    render() {
        //Data in Format: {name: LabelOnXAxis; a: YValue1; b: YValue2}
        /*var data = [
            {
                name: "06.01.17", grade: 6, average: 5
            }, {
                name: "13.01.17", grade: 6, average: 5
            }, {
                name: "20.01.17", grade: 9
            }, {
                name: "27.01.17", grade: 7
            }, {
                name: "10.02.17", grade: 7, average: 7
            }, {
                name: "17.02.17", grade: 6
            }, {
                name: "24.02.17", grade: 7, average: 5
            }, {
                name: "03.03.17", grade: 9, average: 5
            }, {
                name: "10.03.17", grade: 9, average: 9
            }, {
                name: "17.03.17", grade: 5
            }, {
                name: "24.03.17", grade: 6, average: 5
            }, {
                name: "31.03.17", grade: 7, average: 5
            }, {
                name: "07.04.17", grade: 7
            },
        ];*/

        //USe this to calculate Trend-Line: https://github.com/Tom-Alexander/regression-js
//width={1000} height={600}

        let chartData = this.getChartDataWithTrendline();

        return (
            <div className="">
                <ResponsiveContainer width="100%" height={400}>
                    <LineChart data={chartData}
                               margin={{top: 5, right: 30, left: 20, bottom: 5}}>
                        <XAxis dataKey="name"/>
                        <YAxis/>
                        <CartesianGrid strokeDasharray="3 3"/>
                        <Tooltip/>
                        <Legend/>
                        <Line type="monotone" dataKey="Note" stroke="#FF0000" activeDot={{r: 8}} connectNulls/>
                        {this.getAverageLine()}
                        {this.getTrendLine()}
                    </LineChart>
                </ResponsiveContainer>
            </div>
        );
    }

}

export default withRouter(MainChart);
