/**
 * Created by jankothe on 19.06.18.
 */
import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {Badge, Button, Modal, Form} from "react-bootstrap";
import EditMistakeTagRelations from "./EditMistakeTagRelations";
import '../css/EditExamView.css';


class EditExamView extends Component {
    constructor(props) {
        super(props);
        let gradeInputValue = 0;
        let writtenCheckBoxValue = false;

        if (this.props.exam) {
            gradeInputValue = this.props.exam.grade;
            writtenCheckBoxValue = true;
        }

        let currentMistakeTagRelations;
        if (this.props.exam) {
            currentMistakeTagRelations = this.props.exam.mistakeTags;
        } else {
            currentMistakeTagRelations = [];
        }

        this.state = {
            gradeInputValue: gradeInputValue,
            writtenCheckBoxValue: writtenCheckBoxValue,
            newMistakeTagRelations: currentMistakeTagRelations
        }
    }

    componentDidMount() {

    }

    getGradeTextField = () => {
        if (this.state.writtenCheckBoxValue === true) {
            return (
                <Form.Group
                    controlId={"formGrade"}
                >
                    <Form.Label>Deine Note</Form.Label>
                    <Form.Control
                        type={"number"}
                        value={this.state.gradeInputValue}
                        placeholder={"Dein Ergebnis in dieser Exü"}
                        onChange={this.handleGradeChange}
                        isValid={this.getGradeValidationState()}
                        isInvalid={this.getInvalidState(this.getGradeValidationState())}
                    />
                    <Form.Control.Feedback/>
                    <Form.Text>{this.getGradeHelpBlockText()}</Form.Text>
                </Form.Group>
            )
        } else {
            return "";
        }
    };

    getEditMistakeTagRelationsView = () => {
        if (this.state.writtenCheckBoxValue === true) {
            return (
                <div>
                    <h4>Was hättest du in dieser Klausur besser machen können? <Badge pill={"true"}
                                                                                      variant={"primary"}>Neu!</Badge>
                    </h4>
                    <EditMistakeTagRelations mistakeTags={this.props.mistakeTags}
                                             mistakeTagCategories={this.props.mistakeTagCategories}
                                             exam={this.props.exam}
                                             exu={this.props.exu}
                                             newMistakeTagRelations={this.state.newMistakeTagRelations}
                                             updateNewMistakeTagRelations={this.updateNewMistakeTagRelations}
                    />
                </div>
            )
        } else {
            return "";
        }
    };

    updateNewMistakeTagRelations = (mistakeTagId, value) => {
        if (value === true) {
            //Iterate through current relations, add if not existent
            //console.log("This.state.newMistakeTagRelations= " + this.state.newMistakeTagRelations);
            let alreadyExists = false;
            this.state.newMistakeTagRelations.forEach((tag) => {
                if (tag.id === mistakeTagId) {
                    alreadyExists = true;
                }
            });
            if (alreadyExists === false) {
                this.setState({
                    newMistakeTagRelations: [...this.state.newMistakeTagRelations, mistakeTagId]
                })
            }

        } else {
            //Remove from array if existent
            let array = [...this.state.newMistakeTagRelations]; // make a separate copy of the array
            let index = array.indexOf(mistakeTagId);
            if (index !== -1) {
                array.splice(index, 1);
                this.setState({newMistakeTagRelations: array});
            }
        }
    };

    getGradeValidationState = () => {
        if (this.state.gradeInputValue === 0) {
            return null;
        }
        if (this.state.gradeInputValue <= 16 && this.state.gradeInputValue >= 0) {
            return true;
        } else if (this.state.gradeInputValue <= 18) {
            return true;
        } else {
            return false;
        }
    };

    getGradeHelpBlockText = () => {
        if (this.state.gradeInputValue === 0) {
            return "Alle Noten zwischen 1 und 18 Punkten";
        }
        if (this.state.gradeInputValue <= 16 && this.state.gradeInputValue >= 0) {
            return "Alle Noten zwischen 1 und 18 Punkten";
        } else if (this.state.gradeInputValue <= 18) {
            return "Ob das wirklich wahr ist?";
        } else {
            return "Deine Punktzahl muss im Bereich 0-18 Punkten liegen"
        }
    };

    handleGradeChange = (e) => {
        this.setState({gradeInputValue: e.target.value},
            () => {
                //this.refreshSubmitButton();
            });
    };

    handleWrittenCheckboxChange = (e) => {
        let value = e.target.checked;
        this.setState({writtenCheckBoxValue: value},
            () => {
                //this.refreshSubmitButton();
            });

    };

    getWrittenCheckboxHelpBlockText = () => {
        if (this.state.writtenCheckBoxValue === false) {
            return 'Wenn Du diese Exü mitgeschrieben hast, setze hier einen Haken und gib anschließend Deine Note ein und klicke auf "Speichern".';
        } else {
            return 'Wenn Du nicht mitgeschrieben hast oder Dein Ergebnis löschen möchtest, entferne den Haken und klicke auf "Speichern"';
        }
    };

    saveChanges = (e) => {
        e.preventDefault();

        //Folgende Fälle Denkbar
        // Vorher geschrieben & Haken gelassen & Note nicht geändert => NUR MISTAKETAGRELATIONS SPEICHERN
        // Vorher geschrieben & Haken gelassen & Note geändert => UDPATE
        // Vorher geschrieben & Haken weggemacht => DELETE
        // Vorher nicht geschrieben & Haken gesetzt & Note eingegegebn => POST
        // Vorher nicht geschrieben & Haken nicht gesetzt => NICHTS

        // Vorher geschrieben & Haken gelassen & Note nicht geändert => NUR MISTAKETAGRELATIONS SPEICHERN
        if (this.props.exam != null && this.state.writtenCheckBoxValue === true && this.props.exam.grade === this.state.gradeInputValue) {
            this.saveMistakeTagRelations()
                .then(this.props.triggerExamReload)
                .then(this.props.triggerMistakeTagReload)
                .then(this.props.hideEditView);


            // Vorher geschrieben & Haken gelassen & Note geändert => UDPATE
        } else if (this.props.exam != null && this.state.writtenCheckBoxValue === true && this.props.exam.grade !== this.state.gradeInputValue) {
            //UPDATE
            let newGrade = parseInt(this.state.gradeInputValue);
            let newExam = {
                id: this.props.exam.id,
                grade: newGrade,
                exuId: this.props.exam.exuId
            };

            this.props.updateExam(newExam)
                .then(this.saveMistakeTagRelations)
                .then(this.props.triggerExamReload)
                .then(this.props.triggerMistakeTagReload)
                .then(this.props.hideEditView);

        }

        // Vorher geschrieben & Haken weggemacht => DELETE
        if (this.props.exam != null && this.state.writtenCheckBoxValue === false) {
            //DELETE
            this.props.deleteExam(this.props.exam)
                .then(this.props.triggerExamReload)
                .then(this.props.triggerMistakeTagReload)
                .then(this.props.hideEditView);


            // Vorher nicht geschrieben & Haken gesetzt & Note eingegegebn => POST
        } else if (this.props.exam == null && this.state.writtenCheckBoxValue === true && this.state.gradeInputValue != null) {
            //POST
            let newGrade = parseInt(this.state.gradeInputValue);
            let newExam = {
                grade: newGrade,
                exuId: this.props.exu.id
            };
            this.props.createExam(newExam)
                .then(this.saveMistakeTagRelations)
                .then(this.props.triggerExamReload)
                .then(this.props.triggerMistakeTagReload)
                .then(this.props.hideEditView);


            // Vorher nicht geschrieben & Haken nicht gesetzt => NICHTS
        } else if (this.props.exam === null && this.state.writtenCheckBoxValue === false) {
            this.props.hideEditView();
        } else {
            this.props.hideEditView();
        }
    };

    saveMistakeTagRelations = (examId = this.props.exam.id) => {
        //console.log("Save Mistake Tag Relations called with exam Id: " + examId);
        //Check if there are Mistake Tags to add / remove:
        let mistakeTagRelationsToAdd = [];
        let mistakeTagRelationsToRemove = [];

        //If the exam was newly created (that is what there is none in props), all mistake tag relations are new and need to be added
        if (this.props.exam != null && this.props.exam.mistakeTags != null) {
            this.state.newMistakeTagRelations.forEach((element) => {
                if (this.props.exam.mistakeTags.indexOf(element) === -1) {
                    mistakeTagRelationsToAdd.push(element);
                }
            });
        } else {
            console.log("Number of relations to add: " + this.state.newMistakeTagRelations.length);
            mistakeTagRelationsToAdd = [...this.state.newMistakeTagRelations];
        }

        //If the exam was newly created (that is what there is none in props) there can not be any mistake tag relations to remove
        if (this.props.exam != null && this.props.exam.mistakeTags != null) {
            this.props.exam.mistakeTags.forEach((element) => {
                if (this.state.newMistakeTagRelations.indexOf(element) === -1) {
                    mistakeTagRelationsToRemove.push(element);
                }
            });
        }

        //debugger;
        let addConnectionsArray = mistakeTagRelationsToAdd.map((element) => {
            let url = process.env.REACT_APP_API + "/mistakeTagRelation";
            return fetch(url, {
                method: 'post',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    userId: this.props.loginData.userId,
                    authenticationToken: this.props.loginData.authenticationToken,
                    mistakeTagId: element,
                    examId: examId
                })
            });
        });

        //console.log("Login data userID is: " + this.props.loginData.userId);

        let removalConnectionsArray = mistakeTagRelationsToRemove.map((element) => {
            let url = process.env.REACT_APP_API + "/mistakeTagRelation";

            return fetch(url, {
                method: 'DELETE',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    userId: this.props.loginData.userId,
                    authenticationToken: this.props.loginData.authenticationToken,
                    mistakeTagId: element,
                    examId: this.props.exam.id
                })
            });
        });

        let allPromises = addConnectionsArray.concat(removalConnectionsArray);
        return Promise.all(allPromises);
    };


    getSaveButton = () => {
        // What if somebody leaves the grade at 0 points? Will leave the possibility to save for now. Thinking about forcing the change of points
        return (<Button onClick={this.saveChanges} variant="primary" type={"submit"}>Speichern</Button>);
    };

    getWrittenCheckbox = () => {
        let checkedWrittenStatus;

        if (this.state.writtenCheckBoxValue === true) {
            checkedWrittenStatus = true;
        } else {
            checkedWrittenStatus = false;
        }

        return (
            <Form.Check
                type={"checkbox"}
            >
                <Form.Check.Input
                    onChange={this.handleWrittenCheckboxChange}
                    checked={checkedWrittenStatus}
                />

                Ich habe diese Exü mitgeschrieben.
            </Form.Check>
        );

    };

    getInvalidState = (validationState) => {
        if (validationState === null) {
            return false;
        } else {
            return !validationState;
        }
    };

    render() {
        return (
            <Modal show={true} dialogClassName="modal-edit-exam-dialog" className={"modal-edit-exam-container"}>
                <Modal.Header>
                    <Modal.Title>Dein Klausurergebnis bearbeiten</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form className={"right-col-form align-middle"}>
                        <Form.Group
                            controlId={"examWritten"}
                        >
                            <Form.Label>Hast Du diese Exü mitgeschrieben?</Form.Label><br/>
                            {this.getWrittenCheckbox()}
                            <Form.Control.Feedback/>
                            <Form.Text>{this.getWrittenCheckboxHelpBlockText()}</Form.Text>
                        </Form.Group>

                        {this.getGradeTextField()}
                    </Form>
                    <hr/>
                    <div>
                        {this.getEditMistakeTagRelationsView()}
                    </div>

                </Modal.Body>

                <Modal.Footer>
                    <Button variant={"secondary"} onClick={this.props.handleCancelClick}>Abbrechen</Button>
                    {this.getSaveButton()}
                </Modal.Footer>
            </Modal>
        )
    }
}


export default withRouter(EditExamView);