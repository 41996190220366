import dataProtectionStatement from "../img/Datenschutzerklaerung.pdf";
import conditionsOfUse from '../img/Nutzungsbedingungen.pdf';
import {Link, withRouter} from "react-router-dom";
import React, {Component} from "react";

class BottomInformationRow extends Component {
    render() {
        return (
            <div>

                <div className={"bottomInformationRow"}>
                    <a className="bottomLink" href={dataProtectionStatement}>Datenschutz </a>
                    <a className="bottomLink" href={conditionsOfUse}>Nutzungsbedingungen </a>
                    <Link className="bottomLink" to="/impressum">Impressum</Link>
                </div>
            </div>
        )
    };
}

export default withRouter(BottomInformationRow);