/**
 * Created by jankothe on 19.06.18.
 */
import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import MainDataTableRow from './MainDataTableRow';

import {Table} from 'react-bootstrap';


class MainDataTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {

    }

    render() {
        return (
            <Table striped bordered hover>
                <thead>
                <tr>
                    <th>#</th>
                    <th>Datum</th>
                    <th>Fach</th>
                    <th>Durchschnitt</th>
                    <th>Deine Note</th>
                    <th>Bearbeiten</th>
                </tr>
                </thead>
                <tbody>
                {this.getTableRows()}
                </tbody>
            </Table>
        )
    };

    getTableRows(){
        let tableRows = [];
        let i = 1;
        if(this.props.exus && this.props.exus.length >= 1){
        this.props.exus.forEach((exu) => {
            let exam = null;

            if(this.props.exams && this.props.exams.length >= 1) {
                this.props.exams.forEach((currentExam) => {
                    if (currentExam.exuId === exu.id) {
                        exam = currentExam;
                    }
                })
            }else{
            }
            tableRows.push(<MainDataTableRow key={exu.id} no={i}  exam={exam} exu={exu} handleEditButtonClick={this.handleEditButtonClick}/>);
            i++;
        });

        return tableRows;
        }else{
            return (
                <tr><td>Daten zu den ExUs werden geladen...</td></tr>
            );
        }
    };

    updateExam = (exam, exu, newGrade) => {
        this.props.handleExamUpdate(exam, exu, newGrade);
    };

    handleEditButtonClick = (exuId) => {
        this.props.handleEditButtonClickOnExu(exuId);
    };
}

export default withRouter(MainDataTable);