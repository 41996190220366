/**
 * Created by jankothe on 19.06.18.
 */
import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import EditMistakeTagRelationsGroup from "./EditMistakeTagRelationsGroup";
import '../css/EditMistakeTagRelations.css';

class EditMistakeTagRelations extends Component {
    getMistakeTagGroups = () => {


        let sortedMistakeTagCategories = this.props.mistakeTagCategories.sort(function (a, b) {
            if (a.order < b.order) //sort string ascending
                return -1;
            if (a.order > b.order)
                return 1;
            return 0; //default return value (no sorting)
        });

        let mistakeTagGroups = [];
        sortedMistakeTagCategories.forEach((category) => {
            let mistakeTagsInCategory = [];
            this.props.mistakeTags.forEach((mistakeTag) => {
                //debugger;
                if (mistakeTag.categoryId === category.id) {
                    if (mistakeTag.subject === this.props.exu.subject || mistakeTag.subject === "AL") {

                        mistakeTagsInCategory.push(mistakeTag)
                    }
                }
            });

            mistakeTagGroups.push(<EditMistakeTagRelationsGroup key={category.id}
                                                                name={category.name}
                                                                mistakeTags={mistakeTagsInCategory}
                                                                newMistakeTagRelations={this.props.newMistakeTagRelations}
                                                                updateNewMistakeTagRelations={this.props.updateNewMistakeTagRelations}
            />);
        });
        return mistakeTagGroups;
    };


    render() {
        return (
            <div>
                {this.getMistakeTagGroups()}
            </div>

        )
    }
}

export default withRouter(EditMistakeTagRelations);