import React, {Component} from "react";
import {withRouter} from "react-router-dom";

import {PieChart, Pie, Cell, Tooltip, ResponsiveContainer, Legend} from "recharts";


class SubjectDistributionChart extends Component {
    render() {
        let data = [
            {name: 'Strafrecht', value: this.props.numberOfSR},
            {name: 'Zivilrecht', value: this.props.numberOfZR},
            {name: 'Öffentliches Recht', value: this.props.numberOfOR}
        ];
        let COLORS = ['#0088FE', '#00C49F', '#FFBB28'];

        //let RADIAN = Math.PI / 180;

        return (
            <ResponsiveContainer width="100%" height={200}>
                <PieChart height={200} onMouseEnter={this.onPieEnter}>
                    <Pie
                        data={data}
                        dataKey="value"
                        outerRadius={"65%"}
                        fill="#8884d8"
                        paddingAngle={0}
                        label
                    >
                        {
                            data.map((entry, index) => <Cell key={index} fill={COLORS[index % COLORS.length]}/>)
                        }

                    </Pie>
                    <Tooltip/>
                    <Legend/>
                </PieChart>
            </ResponsiveContainer>
        );
    };
};

export default withRouter(SubjectDistributionChart);

/*
<Pie
                    data={data}
                    cx={420}
                    cy={200}
                    startAngle={180}
                    endAngle={0}
                    innerRadius={60}
                    outerRadius={80}
                    fill="#8884d8"
                    paddingAngle={5}
                >
                    {
                        data.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]}/>)
                    }
                </Pie>
 */


//Fancy implementation, see here: http://recharts.org/en-US/examples/CustomActiveShapePieChart
//Will leave for later potentially


/*

const data = [{name: 'Group A', value: 400}, {name: 'Group B', value: 300},
{name: 'Group C', value: 300}, {name: 'Group D', value: 200}];



constructor(props) {
    super(props);
    this.state = {
        activeIndex: 0
    };
}


render() {
    return (
        <PieChart width={800} height={400}>
            <Pie
                activeIndex={this.state.activeIndex}
                activeShape={this.renderActiveShape}
                data={data}
                cx={300}
                cy={200}
                innerRadius={60}
                outerRadius={80}
                fill="#8884d8"
                onMouseEnter={this.onPieEnter}
            />
        </PieChart>
    );
};

renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
        fill, payload, percent, value } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
        <g>
            <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>{payload.name}</text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
            <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none"/>
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none"/>
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`PV ${value}`}</text>
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
                {`(Rate ${(percent * 100).toFixed(2)}%)`}
            </text>
        </g>
    );
};

onPieEnter = (data, index) => {
    this.setState({
        activeIndex: index,
    });
};*/

