import React, {Component} from 'react';
import {Form, FormGroup, FormControl, Button, Alert} from 'react-bootstrap';
import {withRouter} from 'react-router-dom';
import validator from 'validator';


class RequestPasswordResetView extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            currentlyLoading: false,
            emailInputValue: '',
            readyToSubmit: false,
            requestSuccessful: null,
            requestFinished: false,
            error: null
        };
    }

    getEmailValidationState = () => {
        if (this.state.emailInputValue.length === 0) {
            return null;
        } else {
            if (this.state.emailInputValue.endsWith("@law-school.de") && validator.isEmail(this.state.emailInputValue)) {
                return 'success';
            } else {
                return 'error';
            }
        }
    };

    handleEmailChange = (e) => {
        this.setState({emailInputValue: e.target.value},
            () => {
                this.refreshSubmitButton();
            });
    };

    refreshSubmitButton = () => {
        if (this.getEmailValidationState() === 'success') {
            this.setState({
                readyToSubmit: true
            })
        } else {
            this.setState({
                readyToSubmit: false
            })
        }
    };

    handleCancelClick = (e) => {
        this.props.goBack();
    };


    getLoader = () => {
        if (this.state.currentlyLoading) {
            return <div class="loader"></div>
        } else {
            return ""
        }
    };


    handleResetPasswordRequestSubmit = (e) => {
        e.preventDefault();
        this.setState({
            currentlyLoading: true,
        });

        let url = process.env.REACT_APP_API + "/requestResetPasswordToken";
        fetch(url, {
            method: 'post',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: this.state.emailInputValue
            })
        })
            .then((resp) => resp.json())
            .then(answer => {
                this.setLoadingStatus(false);
                let result = answer;
                if (result.success === true) {
                    this.setState({
                        currentlyLoading: false,
                        requestFinished: true,
                        requestSuccessful: true
                    });
                } else {
                    if (result.error) {
                        this.setState({
                            currentlyLoading: false,
                            requestFinished: true,
                            requestSuccessful: true,
                            error: result.error
                        });
                    } else {
                        this.setState({
                            currentlyLoading: false,
                            requestFinished: true,
                            requestSuccessful: true,
                            error: null
                        });
                    }
                }
            })
            .catch((e) => {
                    this.setState({
                        currentlyLoading: false,
                        requestFinished: true,
                        requestSuccessful: true,
                        error: null
                    });
                }
            )
    };

    getForm = () => {
        return (
            <form className={"right-col-form"}>
                <FormGroup
                    controlId={"formEmail"}
                    validationState={this.getEmailValidationState()}
                >
                    <Form.Label>Email Adresse</Form.Label>
                    <FormControl
                        type={"email"} value={this.state.emailInputValue}
                        placeholder={"Deine E-Mail Adresse"}
                        onChange={this.handleEmailChange}
                    />
                </FormGroup>

                <Button onClick={this.handleCancelClick}>Zurück</Button>
                <Button bsStyle="primary" onClick={this.handleResetPasswordRequestSubmit}
                        disabled={!this.state.readyToSubmit}>Neues Passwort anfordern</Button><br/>
                <div> {this.getLoader()}</div>
                <Alert variant="danger">In den letzten Wochen gab es verstärkt Probleme beim Login. Falls du in den letzten Tagen nicht auf deinen Account zugreifen konntest, Dein altes Passwort aber noch weißt, versuche bitte, Dich damit einzuloggen. <br />
                Das wiederherstellen von Passwörtern funktioniert im Moment nicht richtig.
                    Wenn Du keine Mail bekommst, schreib mir bitte eine Mail.

                </Alert>
            </form>
        )
    };

    getSuccessMessage = () => {
        return (
            <div>
                <h2>Erfolg</h2>
                <p>Du hast einen Link per Mail bekommen. Bitte überprüfe Deine E-Mails um Dein Passwort zu ändern.</p>
                <Button onClick={this.handleCancelClick}>Zurück zum Login</Button>
            </div>
        )
    };

    getErrorMessage = () => {
        //3 - Reset Password: requestNewPasswordToken: Not a valid email adress
        //4 - Reset Password: requestNewPasswordToken: Email Address not in database
        let errorMessage = "";
        switch (this.state.error) {
            case null:
                errorMessage = "Ein unbekannter Fehler ist aufgetreten. Dein Passwort konnte nicht wiederhergestellt werden. Bitte versuche es später erneut oder wende dich an " + +process.env.REACT_APP_SUPPORT_EMAIL + " ich melde mich bei Dir, so schnell es geht!";
            case 3:
                errorMessage = "Deine E-Mail Adresse entspricht nicht dem geforderten Format. Hast du dich vertippt? Handelt es sich um eine @law-school.de Adresse?";
            case 4:
                errorMessage = "Deine E-Mail Adresse ist im System nicht bekannt. Bitte melde dich an."

        }
        return (
            <div>
                <h2>Fehler</h2>
                <p>{this.getErrorMessage()}</p>
            </div>
        )
    };


    render() {
        if (this.state.requestFinished !== true) {
            return (this.getForm());
        } else {
            if (this.state.requestSuccessful === true) {
                return (this.getSuccessMessage());
            } else {
                return (this.getErrorMessage());
            }
        }
    }

}

export default withRouter(RequestPasswordResetView);