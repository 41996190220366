/**
 * Created by jankothe on 19.06.18.
 */
import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {Navbar, Nav, NavItem} from "react-bootstrap";
import dataProtectionStatement from "../img/Datenschutzerklaerung.pdf";

class CustomFooterBar extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    impressumClicked = (e) => {
        this.props.history.push("/impressum");
    };

    feedbackClicked = (e) => {
        this.props.history.push("/feedback");
    };

    componentDidMount() {

    }

    render() {
        /* onClick={this.dataProtectionClicked}*/

        return (
            <Navbar bg="dark" variant={"dark"} >

                    <Nav className="mr-auto">
                        <Nav.Link href="#" onClick={this.impressumClicked}>
                            Impressum
                        </Nav.Link>
                        <Nav.Link href={dataProtectionStatement}>
                            Datenschutz
                        </Nav.Link>
                        <Nav.Link href="#" onClick={this.feedbackClicked}>
                            Feedback
                        </Nav.Link>
                    </Nav>
                    <Nav>
                        <Navbar.Text>
                            Jan Kothe
                        </Navbar.Text>

                    </Nav>
            </Navbar>
        )
    }
}


export default withRouter(CustomFooterBar);



