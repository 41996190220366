import React, {Component} from 'react';
import {Form, FormControl, Button} from 'react-bootstrap';
import {withRouter} from 'react-router-dom';
import validator from 'validator';
//import ReactGA from "react-ga";


class SignupForm extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            emailInputValue: '',
            passwordInputValue: '',
            readyToSubmit: false
        };
    }

    onFormSubmit = (e) => {
        /*ReactGA.event({
            category: 'User',
            action: 'Login Button Clicked'
        });*/
        e.preventDefault();
        this.props.onSubmit(this.state.emailInputValue, this.state.passwordInputValue);
    };

    getEmailValidationState = () => {
        if (this.state.emailInputValue.length === 0) {
            return null;
        } else {
            if (this.state.emailInputValue.endsWith("@law-school.de") && validator.isEmail(this.state.emailInputValue)) {
                return 'success';
            } else {
                return 'error';
            }
        }
    };

    handleEmailChange = (e) => {
        this.setState({emailInputValue: e.target.value},
            () => {
                this.refreshSubmitButton();
            });
    };

    handlePasswordChange = (e) => {
        this.setState({passwordInputValue: e.target.value},
            () => {
                this.refreshSubmitButton();
            });
    };

    refreshSubmitButton = () => {
        if (this.getEmailValidationState() === 'success') {
            this.setState({
                readyToSubmit: true
            })
        } else {
            this.setState({
                readyToSubmit: false
            })
        }
    };

    render() {

        return (
            <div>
                <form className={"right-col-form"}>
                    <Form.Group
                        controlId={"formEmail"}
                    >
                        <Form.Label>Email Adresse</Form.Label>
                        <FormControl
                            type={"email"} value={this.state.emailInputValue}
                            placeholder={"Deine E-Mail Adresse"}
                            onChange={this.handleEmailChange}
                        />
                    </Form.Group>

                    <Form.Group
                        controlId={"formPassword"}
                    >
                        <Form.Label>Passwort</Form.Label>
                        <FormControl
                            type={"password"} value={this.state.passwordInputValue}
                            placeholder={"Dein Passwort"}
                            onChange={this.handlePasswordChange}
                        />
                    </Form.Group>

                    <Button variant="primary" block onClick={this.onFormSubmit}
                            disabled={!this.state.readyToSubmit}>Einloggen</Button>
                    <div> {this.props.getLoader()}</div>
                </form>
                <br />
                <Button variant={"secondary"} block onClick={this.props.resetPasswordButtonClicked}>Passwort vergessen?</Button>
            </div>
        );
    }

}

export default withRouter(SignupForm);