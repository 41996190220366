import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {
    Alert
} from "react-bootstrap";
import {
    BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ReferenceLine, ResponsiveContainer,
} from 'recharts';

class MistakeTagWeightingChart extends Component {
    render() {
        let data = this.getData();

        let diagramBarSize = 50;
        let containerHeight = (diagramBarSize + 10) * data.length + 60;

        if (data.length > 0) {
            return (
                <ResponsiveContainer width="100%" height={containerHeight}>
                    <BarChart
                        layout="vertical"
                        data={data}
                        margin={{
                            top: 20, right: 0, bottom: 20, left: 5
                        }}
                    >
                        <Legend verticalAlign="top" height={36}/>
                        <Tooltip/>
                        <CartesianGrid strokeDasharray="3 3" horizontal={true}/>
                        <XAxis type="number" orientation={"top"} allowDecimals={true} tickCount={5}
                               axisLine={false}/>
                        <XAxis type="number" orientation={"bottom"} allowDecimals={true} tickCount={5}
                               axisLine={false}/>
                        <YAxis type={"category"} dataKey="name" hide={false} width={200}/>
                        <Tooltip/>

                        <ReferenceLine x={0} stroke="#000"/>
                        <Bar name={"Notenauswirkung in Punkten"} barSize={diagramBarSize - 10} dataKey="weighting" fill="#76a8f7">

                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            );
        }else {
            return (
                <div>
                    <br/>
                    <Alert variant="danger">
                        Du hast bisher keine Fehler eingetragen. Beim Eintragen und Bearbeiten eines Klausurergebnisses,
                        kannst du jetzt auch eintragen, welche Fehler du gemacht hast. Sie erscheinen dann hier.
                    </Alert>
                </div>


            )
        }
    }

    getData = () =>{
        /*Zielformat
            [{name: "", weighting: 1}]
         */

        let data = [];
        //This could propably be done with a map function. I like it like this.
        this.props.mistakeTags.forEach((mistakeTag) => {
            let countOwnMistakes = this.getNumberOfMistakeTagOccurrences(mistakeTag.id);
            if (countOwnMistakes > 0) {
                data.push({
                    name: mistakeTag.name, weighting: (Math.round(100 * mistakeTag.weighting) / 100)
                })
            }
        });

        data.sort(function (a, b) {
            if (a.weighting < b.weighting)
                return -1;
            if (a.weighting > b.weighting)
                return 1;
            return 0; //default return value (no sorting)
        });

        return data;
    };

    getNumberOfMistakeTagOccurrences = (id) => {
        let exams = this.props.exams;

        let number = 0;
        exams.forEach((exam) => {
            exam.mistakeTags.forEach((mistakeTag) => {
                if (mistakeTag === id) {
                    number++;
                }
            })
        });

        return number;
    };


}

export default withRouter(MistakeTagWeightingChart);