import React, {Component} from 'react';
import {FormControl, Form, Button} from 'react-bootstrap';
import {withRouter} from 'react-router-dom';
import validator from 'validator';
import dataProtectionStatement from '../img/Datenschutzerklaerung.pdf';
import conditionsOfUse from '../img/Nutzungsbedingungen.pdf';
//import ReactGA from "react-ga";


class SignupForm extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            emailInputValue: '',
            passwordInputValue: '',
            repeatPasswordInputValue: '',
            classYearInputValue: 0,
            checkBoxValue: false,
            readyToSubmit: false
        };
    }

    onFormSubmit = (e) => {
        /*ReactGA.event({
            category: 'User',
            action: 'Signup Button Clicked'
        });*/
        e.preventDefault();
        this.props.onSubmit(this.state.emailInputValue, this.state.passwordInputValue, this.state.classYearInputValue);
    };

    getEmailValidationState = () => {
        if (this.state.emailInputValue.length === 0) {
            return null;
        } else {
            if (this.state.emailInputValue.endsWith("@law-school.de") && validator.isEmail(this.state.emailInputValue)) {
                return true;
            } else {
                return false;
            }
        }
    };

    getClassYearValidationState = () => {
        if (this.state.classYearInputValue === 0) {
            return null;
        }
        if (this.state.classYearInputValue <= 2100 && this.state.classYearInputValue >= 1999) {
            return true;
        } else {
            return false;
        }
    };

    getCheckboxValidationState = () => {
        if (this.state.checkBoxValue === false) {
            return false;
        } else {
            return true;
        }
    };

    handleEmailChange = (e) => {
        this.setState({emailInputValue: e.target.value},
            () => {
                this.refreshSubmitButton();
            });
    };

    handleClassYearChange = (e) => {
        this.setState({classYearInputValue: e.target.value},
            () => {
                this.refreshSubmitButton();
            });
    };

    handleCheckboxChange = (e) => {
        let value = e.target.checked;
        this.setState({checkBoxValue: value},
            () => {
                this.refreshSubmitButton();
            });
    };

    refreshSubmitButton = () => {
        /*let checkboxValidationState = this.getCheckboxValidationState();
        let classYearValidationState = this.getClassYearValidationState();
        let emailValidationState = this.getEmailValidationState();
        let pwValidationState = this.getFirstPasswordValidationState();
        let spwValidationState = this.getSecondPasswordValidationState();*/
        if (this.getCheckboxValidationState() === true
            && this.getClassYearValidationState() === true
            && this.getEmailValidationState() === true
            && this.getFirstPasswordValidationState() === true
            && this.getSecondPasswordValidationState() === true
        ) {
            this.setState({
                readyToSubmit: true
            })
        } else {
            this.setState({
                readyToSubmit: false
            })
        }
    };

    handlePasswordChange = (e) => {
        this.setState({passwordInputValue: e.target.value},
            () => {
                this.refreshSubmitButton();
            });
    };

    handleRepeatPasswordChange = (e) => {
        this.setState({repeatPasswordInputValue: e.target.value},
            () => {
                this.refreshSubmitButton();
            });
    };

    getFirstPasswordValidationState = () => {
        if (this.state.passwordInputValue.length === 0) {
            return null;
        } else if (this.state.passwordInputValue.length >= 6) {
            return true
        } else {
            return false;
        }
    };

    getSecondPasswordValidationState = () => {
        if (this.state.repeatPasswordInputValue.length === 0) {
            return null;
        } else if (this.state.passwordInputValue === this.state.repeatPasswordInputValue) {
            return true;
        } else {
            return false;
        }
    };

    getInvalidState = (validationState) => {
        if (validationState === null) {
            return false;
        } else {
            return !validationState;
        }
    };

    render() {
        //<Form.Control.Feedback>Looks Good</Form.Control.Feedback>
        return (
            <Form className={"right-col-form align-middle"}>
                <Form.Group
                    controlId={"formEmail"}
                >
                    <Form.Label>Email Adresse</Form.Label>
                    <Form.Control
                        type={"email"} value={this.state.emailInputValue}
                        placeholder={"Deine E-Mail Adresse"}
                        onChange={this.handleEmailChange}
                        isInvalid={this.getInvalidState(this.getEmailValidationState())}
                        isValid={this.getEmailValidationState()}
                    />
                    <Form.Text>Es funktionieren nur gültige Mailadressen der Bucerius Law School.</Form.Text>
                </Form.Group>

                <Form.Group
                    controlId={"formPassword"}
                >
                    <Form.Label>Passwort</Form.Label>
                    <Form.Control
                        type={"password"} value={this.state.passwordInputValue}
                        placeholder={"Passwort"}
                        onChange={this.handlePasswordChange}
                        isInvalid={this.getInvalidState(this.getFirstPasswordValidationState())}
                        isValid={this.getFirstPasswordValidationState()}
                    />
                    <Form.Control
                        type={"password"} value={this.state.repeatPasswordInputValue}
                        placeholder={"Passwort wiederholen"}
                        onChange={this.handleRepeatPasswordChange}
                        isInvalid={this.getInvalidState(this.getSecondPasswordValidationState())}
                        isValid={this.getSecondPasswordValidationState()}
                    />
                    <Form.Text>Mindestens 6 Zeichen</Form.Text>
                </Form.Group>

                <Form.Group
                    controlId={"formClassYear"}
                >
                    <Form.Label>Jahrgang</Form.Label>
                    <Form.Control
                        type={"number"} value={this.state.value}
                        placeholder={"Dein Jahrgang an der Bucerius Law School"}
                        onChange={this.handleClassYearChange}
                        isInvalid={this.getInvalidState(this.getClassYearValidationState())}
                        isValid={this.getClassYearValidationState()}
                    />
                    <FormControl.Feedback/>
                    <Form.Text>Zum Beispiel "2013"</Form.Text>
                </Form.Group>

                <Form.Group
                    controlId={"formDataProtectionCheck"}
                >
                    <Form.Check
                        type={"checkbox"}
                        label={"Klicke hier"}

                    >
                        <Form.Check.Input
                            onChange={this.handleCheckboxChange}
                            isInvalid={this.getInvalidState(this.getCheckboxValidationState())}
                            isValid={this.getCheckboxValidationState()}
                        />
                        <small>Ich habe die <a href={dataProtectionStatement}>Datenschutzerklärung</a> sowie die <a
                            href={conditionsOfUse}>Nutzungsbedingungen</a> gelesen und bin einverstanden.</small>
                    </Form.Check>
                </Form.Group>
                <Button variant="primary" block onClick={this.onFormSubmit} disabled={!this.state.readyToSubmit}>Jetzt
                    registrieren</Button>{this.props.getLoader()}
            </Form>
        );
    }

}

export default withRouter(SignupForm);