/**
 * Created by jankothe on 19.06.18.
 */
import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {Button, Modal} from "react-bootstrap";


class ShowTrendWarningWindow extends Component {
    constructor(props) {
        super(props);

        this.state = {}
    }

    componentDidMount() {

    }

    render() {
        return (
            <Modal
                show={true}
                dialogClassName={"modal-90w"}
            >

                <Modal.Header>
                    <Modal.Title>Hinweis zum Examenstrend</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p>
                        Mit dem Examenstrend kannst du Dir anzeigen lassen, wie sich Deine Noten entwickeln.
                        Es handelt sich um eine lineare Trendlinie, die nach der Methode der
                        kleinsten Quadrate berechnet wird.
                        Bitte sei Dir bewusst, dass die Aussagekraft dieser Trendlinie begrenzt ist. Gerade wenn
                        du noch wenige Ergebnisse eingetragen hast, schwankt die Linie stark.
                    </p>
                    <p>
                        Die bisherigen Teilnehmer haben die Trendlinie als sehr motivierend empfunden, weil sie
                        aufzeigt, dass Deine Klausurergebnisse sich in der Regel verbessern. Nimm sie bitte
                        trotzdem nicht zu ernst.
                    </p>
                </Modal.Body>

                <Modal.Footer>
                    <Button onClick={this.props.handleCancelButtonClick} variant={"secondary"}>Doch nicht!</Button>
                    <Button onClick={this.props.handleConfirmButtonClick} variant="primary">Zeig her!</Button>
                </Modal.Footer>

            </Modal>

        )
    }
}


export default withRouter(ShowTrendWarningWindow);