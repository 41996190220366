/**
 * Created by jankothe on 19.06.18.
 */
import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {
    Badge,
    Button,
    Col,
    Container,
    Modal,
    Row,
    Alert,
    Tabs,
    Tab, Table, Popover, OverlayTrigger
} from "react-bootstrap";
import '../css/MistakeAnalysis.css';

import {
    Bar, XAxis, YAxis, CartesianGrid,
    ResponsiveContainer, BarChart, Legend, Tooltip
} from 'recharts';
import MistakeTagWeightingChart from "./MistakeTagWeightingChart";


class MistakeAnalysis extends Component {
    componentDidMount() {

    }

    render() {
        return (
            <Modal
                show={true}
                dialogClassName="modal-90w"
            >
                <Modal.Header>
                    <Modal.Title>Deine individuelle Fehleranalyse</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Container fluid>
                        <Row className={"align-items-center full-row"}>
                            <Col md={12} lg={12} className={""}>
                                <Tabs defaultActiveKey={1} id="uncontrolled-tab-example">
                                    <Tab eventKey={1} title="Tabelle">
                                        {this.getMistakeAnalysisTable()}
                                    </Tab>
                                    <Tab eventKey={2} title="Grafik">
                                        <Col md={12} lg={6} className={""}>
                                            {this.getIndividualMistakeCountChart()}
                                        </Col>
                                        <Col md={12} lg={6} className={""}>
                                            <MistakeTagWeightingChart mistakeTags={this.props.mistakeTags}
                                                                      exams={this.props.exams}
                                            />
                                        </Col>
                                    </Tab>
                                </Tabs>
                            </Col>
                        </Row>
                    </Container>

                </Modal.Body>

                <Modal.Footer>
                    <Button onClick={this.props.handleDoneClick}>Fertig</Button>
                </Modal.Footer>

            </Modal>
        )
    }


    getMistakeAnalysisTable = () => {
        let tableRows = [];

        let data = [];
        this.props.mistakeTags.forEach((mistakeTag) => {
            let countOwnMistakes = this.getNumberOfMistakeTagOccurrences(mistakeTag.id);
            if (countOwnMistakes > 0) {
                let category = this.props.mistakeTagCategories.find(category => category.id === mistakeTag.categoryId);
                let categoryName = category ? category.name : "";
                data.push({
                    id: mistakeTag.id,
                    name: mistakeTag.name,
                    categoryName: categoryName,
                    countOwnMistakes: countOwnMistakes,
                    countTotalMistakes: mistakeTag.totalCountAllExams,
                    percentageOwnMistakes: this.roundToTwoDecimalPlaces(100 / this.getTotalNumberOfIndividualMistakes() * countOwnMistakes),
                    percentageTotalMistakes: this.roundToTwoDecimalPlaces(100 / this.getTotalNumberOfMistakesAllExams() * mistakeTag.totalCountAllExams),
                    weighting: mistakeTag.weighting
                });
            }
        });


        if (data.length > 0) {
            let dataSortedByNumber = [];

            //This is a workaround to add some space at the bottom of the chart
            /*dataSortedByNumber.push({
                name: "", Anzahl: 0
            });*/

            dataSortedByNumber.push(...data.sort(function (a, b) {
                if (a.countOwnMistakes > b.countOwnMistakes) //sort string ascending
                    return -1;
                if (a.countOwnMistakes < b.countOwnMistakes)
                    return 1;
                return 0; //default return value (no sorting)
            }));

            dataSortedByNumber.forEach((mistakeTag) => {
                tableRows.push(<tr key={mistakeTag.id}>
                    <td>{mistakeTag.name}</td>
                    <td>{mistakeTag.categoryName}</td>
                    <td><b>{mistakeTag.countOwnMistakes} Mal</b></td>
                    <td>{mistakeTag.percentageOwnMistakes} %</td>
                    <td>
                        <OverlayTrigger
                            overlay={
                                <Popover id={1} title={"Was ist das?"}>
                                    <p>Mit
                                        Notenauswirkung wird die durchschnittliche Abweichung der Note einer
                                        Klausur
                                        mit diesem Fehler von der durchschnittlichen Note aller eingetragener
                                        Klausuren bezeichnet.</p>
                                    <p>Ein Wert von z.B. -1 bedeutet, dass Klausuren mit diesem Fehler im
                                        Durchschnitt einen Punkt schlechter bewertet werden.</p>
                                    <p><b>Beachte: Die Datenbasis
                                        hierfür ist bisher leider noch zu klein um wirklich sinnvolle Aussagen zu treffen.</b></p>
                                </Popover>
                            }
                            placement="bottom"
                            delayShow={500}
                            delayHide={50}
                        >
                            <div>{this.roundToTwoDecimalPlaces(mistakeTag.weighting)} Punkte <Badge pill={"true"} variant={"primary"}>?</Badge></div>
                        </OverlayTrigger>
                    </td>
                </tr>)
            });

            return (
                <Table hover>
                    <thead>
                    <tr>
                        <th>Fehler</th>
                        <th>Oberkategorie des Fehlers</th>
                        <th>Häufigkeit in deinen Klausuren</th>
                        <th>Anteil an deinen Fehlern</th>
                        <th><OverlayTrigger
                            overlay={<Popover id={1} title={"Was ist das?"}>
                                <p>Mit
                                    Notenauswirkung bezeichnen wir die durchschnittliche Abweichung der Note einer
                                    Klausur
                                    mit diesem Fehler von der durchschnittlichen Note aller eingetragener
                                    Klausuren.</p>
                                <p>Ein Wert von z.B. -1 bedeutet, dass Klausuren mit diesem Fehler im
                                    Durchschnitt einen Punkt schlechter bewertet werden.</p>
                                <p><b>Beachte: Die Datenbasis
                                    hierfür ist bisher leider noch zu klein um wirklich sinnvolle Aussagen zu treffen.</b></p>
                            </Popover>}
                            placement="bottom"
                            delayShow={500}
                            delayHide={50}
                        >
                            <div>
                                Notenauswirkung <Badge pill={"true"} variant={"primary"}>BETA</Badge>
                            </div>
                        </OverlayTrigger></th>
                    </tr>
                    </thead>
                    <tbody>
                    {tableRows}
                    </tbody>
                </Table>
            );
        } else {
            return (
                <div>
                    <br/>
                    <Alert variant="danger">
                        Du hast bisher keine Fehler eingetragen. Beim Eintragen und Bearbeiten eines Klausurergebnisses,
                        kannst du jetzt auch eintragen, welche Fehler du gemacht hast. Sie erscheinen dann hier.
                    </Alert>
                </div>


            )
        }


    };


    getNumberOfMistakeTagOccurrences = (id) => {
        let exams = this.props.exams;

        let number = 0;
        exams.forEach((exam) => {
            exam.mistakeTags.forEach((mistakeTag) => {
                if (mistakeTag === id) {
                    number++;
                }
            })
        });

        return number;
    };

    getTotalNumberOfIndividualMistakes = () => {
        let exams = this.props.exams;

        let number = 0;
        exams.forEach((exam) => {
            exam.mistakeTags.forEach((mistakeTag) => {
                number++;
            })
        });

        return number;
    };

    getTotalNumberOfMistakesAllExams = () => {
        let number = 0;
        this.props.mistakeTags.forEach((mistakeTag) => {
            number += mistakeTag.totalCountAllExams;
        });
        return number;
    };

    getIndividualMistakeCountChart = () => {
        let mistakeTags = this.props.mistakeTags;

        let data = [];
        mistakeTags.forEach((mistakeTag) => {
            let countOwnMistakes = this.getNumberOfMistakeTagOccurrences(mistakeTag.id);
            if (countOwnMistakes > 0) {
                data.push({
                    name: mistakeTag.name,
                    countOwnMistakes: countOwnMistakes,
                    countTotalMistakes: mistakeTag.totalCountAllExams,
                    percentageOwnMistakes: this.roundToTwoDecimalPlaces(100 / this.getTotalNumberOfIndividualMistakes() * countOwnMistakes),
                    percentageTotalMistakes: this.roundToTwoDecimalPlaces(100 / this.getTotalNumberOfMistakesAllExams() * mistakeTag.totalCountAllExams)
                });
            }
        });

        if (data.length > 0) {
            let dataSortedByNumber = [];

            //This is a workaround to add some space at the bottom of the chart
            /*dataSortedByNumber.push({
                name: "", Anzahl: 0
            });*/

            dataSortedByNumber.push(...data.sort(function (a, b) {
                if (a.countOwnMistakes > b.countOwnMistakes) //sort string ascending
                    return -1;
                if (a.countOwnMistakes < b.countOwnMistakes)
                    return 1;
                return 0; //default return value (no sorting)
            }));


            let diagramBarSize = 50;
            let containerHeight = (diagramBarSize + 10) * dataSortedByNumber.length + 60;
            //<LabelList dataKey="name" position="left"/>
            return (
                <ResponsiveContainer width="100%" height={containerHeight}>
                    <BarChart
                        layout="vertical"
                        data={dataSortedByNumber}
                        margin={{
                            top: 20, right: 0, bottom: 20, left: 5,
                        }}
                    >
                        <CartesianGrid horizontal={true} stroke="#f5f5f5"/>

                        <Legend verticalAlign="top" height={36}/>
                        <Tooltip/>
                        <Bar name={"Häufigkeit in eigenen Klausuren"} barSize={diagramBarSize - 10}
                             dataKey="countOwnMistakes" fill="#76a8f7" background={{fill: '#eee'}}>

                        </Bar>
                        <YAxis type={"category"} dataKey="name" hide={false} width={200}/>
                        <XAxis type="number" orientation={"top"} allowDecimals={true} tickCount={5}
                               axisLine={false}/>
                        <XAxis type="number" orientation={"bottom"} allowDecimals={true} tickCount={5}
                               axisLine={false}/>
                    </BarChart>
                </ResponsiveContainer>
            );
        } else {
            return (
                <div>
                    <br/>
                    <Alert variant="danger">
                        Du hast bisher keine Fehler eingetragen. Beim Eintragen und Bearbeiten eines Klausurergebnisses,
                        kannst du jetzt auch eintragen, welche Fehler du gemacht hast. Sie erscheinen dann hier.
                    </Alert>
                </div>


            )
        }
    };

    roundToTwoDecimalPlaces = (f) => {
        return (Math.round(100 * f) / 100);
    };
}


export default withRouter(MistakeAnalysis);