/**
 * Created by jankothe on 19.06.18.
 */
import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {Button, Table, Badge} from "react-bootstrap";
import SubjectDistributionChart from "./SubjectDistributionChart";
import '../css/StatisticsPanel.css';


class StatisticsPanel extends Component {
    componentDidMount() {

    }


    render() {
        return (
            <div className="">
                <Table striped bordered hover>
                    <thead>
                    </thead>
                    <tbody>
                    <tr>
                        <td colSpan="2">{this.getShowTrendButton()}</td>
                    </tr>
                    <tr>
                        <td colSpan="2">{this.getShowAverageButton()}</td>
                    </tr>
                    <tr>
                        <td colSpan="2">{/*variant="primary"*/}
                            <Button variant={"secondary"} block onClick={this.props.showMistakeAnalysisClicked}>Fehleranalyse <Badge pill={"true"} variant={"primary"}>Neu!</Badge></Button>
                        </td>
                    </tr>
                    <tr>
                        <td>Anzahl geschriebene Exüs:</td>
                        <td>{this.numberOfWrittenExus()}</td>
                    </tr>
                    <tr>
                        <td>Durchschnittliche Exü Note:</td>
                        <td>{this.exuAverage()} Punkte</td>
                    </tr>
                    <tr>
                        <td>Anteil deiner Klausuren über 9 Punkten</td>
                        <td>{this.percentOverNine()} %</td>
                    </tr>
                    <tr>
                        <td>
                            Aufteilung Deiner Exüs:
                            <SubjectDistributionChart numberOfSR={this.numberOfExusWithSubject("SR")}
                                                      numberOfZR={this.numberOfExusWithSubject("ZR")}
                                                      numberOfOR={this.numberOfExusWithSubject("OR")}/>
                        </td>
                        <td>
                            Strafrecht: {this.roundToTwoDecimalPlaces(100 / this.numberOfWrittenExus() * this.numberOfExusWithSubject("SR"))} %<br/>
                            Zivilrecht: {this.roundToTwoDecimalPlaces(100 / this.numberOfWrittenExus() * this.numberOfExusWithSubject("ZR"))} %<br/>
                            Öffentliches
                            Recht: {this.roundToTwoDecimalPlaces(100 / this.numberOfWrittenExus() * this.numberOfExusWithSubject("OR"))} %
                        </td>
                    </tr>
                    <tr>
                        <td>Durchschnitt im Strafrecht:</td>
                        <td>{this.averageGradeInSubject("SR")} Punkte</td>
                    </tr>
                    <tr>
                        <td>Durchschnitt im Zivilrecht:</td>
                        <td>{this.averageGradeInSubject("ZR")} Punkte</td>
                    </tr>
                    <tr>
                        <td>Durchschnitt im öffentlichen Recht:</td>
                        <td>{this.averageGradeInSubject("OR")} Punkte</td>
                    </tr>
                    </tbody>
                </Table>

            </div>
        );
    }

    getShowTrendButton = () => {
        let buttonText = "";
        if(this.props.showTrend === true){
            buttonText = "Trend ausblenden"
        }else{
            buttonText = "Trend einblenden"
        }
        return <Button variant={"secondary"} block onClick={this.props.showTrendButtonClicked}>{buttonText}</Button>
    };

    getShowAverageButton = () => {
        let buttonText = "";
        if(this.props.showAverage === true){
            buttonText = "Durchschnitte ausblenden"
        }else{
            buttonText = "Durchschnitte einblenden"
        }
        return <Button variant={"secondary"} block onClick={this.props.showAverageButtonClicked}>{buttonText}</Button>
    };

    numberOfWrittenExus = () => {
        let i = 0;
        this.props.exams.forEach((element) => {
            if (element.grade && element.grade > 0) {
                i++;
            }
        });
        return i;
    };

    exuAverage = () => {
        let sum = 0;
        this.props.exams.forEach((element) => {
            sum += element.grade;
        });
        sum = this.roundToTwoDecimalPlaces(sum / this.props.exams.length);
        return sum;
    };

    lastSixExamsAverage = () => {

    };

    percentAboveExuAverage = () => {

    };

    percentOverNine = () => {
        let i = 0;
        this.props.exams.forEach((element) => {
            if (element.grade >= 9) {
                i++;
            }
        });
        let percent = this.roundToTwoDecimalPlaces(100 / this.numberOfWrittenExus() * i);
        return percent;
    };

    numberOfExusWithSubject = (subject) => {
        let i = 0;
        this.props.exams.forEach((element) => {
            let result = this.findExuWithId(element.exuId);
            if (result !== false && result.subject === subject) {
                i++;
            }
        });
        return i;
    };

    averageGradeInSubject = (subject) => {
        let sumOfGrades = 0;
        let numberOfExusInSubject = 0;
        this.props.exams.forEach((exam) => {
            let result = this.findExuWithId(exam.exuId);
            if (result !== false && result.subject === subject) {
                sumOfGrades += exam.grade;
                numberOfExusInSubject++;
            }
        });
        return this.roundToTwoDecimalPlaces(sumOfGrades/numberOfExusInSubject);
    }

    roundToTwoDecimalPlaces = (f) => {
        return (Math.round(100 * f) / 100);
    };

    findExuWithId(id) {
        let result = false;
        this.props.exus.forEach((element) => {
            if (element.id === id) {
                result = element;
            }
        });
        return result;
    };


    /*
Ideen für Statistiken:
- Anzahl Klausuren
- Durchschnitt aller Exüs
- Durchschnitt pro Fach
- Durchschnitt der letzten 6 Exüs
- Trendlinie
- % Überdurchschnittlich
- % über 9 Punkte
[- Anzahl nicht geschriebene Klausuren]
- Anteil der Exüs im Fachbereich (Kreisdiagramm?)
- Vorhersage: Anzahl Klausuren bis zum geplanten Examenstermin



Andere Ideen:
- Evaluation der Exü: War sie gut gestellt / Realistisch?
- Preis auslobung: Wer schreibt die meisten
- Preis Auslobung alle: Wenn 500 Einträge: Glühwein umsonst
- Begonnen, vorgehabt, zu schreiben etc?

 */
}

export default withRouter(StatisticsPanel);