/**
 * Created by jankothe on 19.06.18.
 */
import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import EditMistakeTagRelationsCheckbox from "./EditMistakeTagRelationsCheckbox";
import '../css/EditMistakeTagRelations.css';

class EditMistakeTagRelationsGroup extends Component {
    getCheckboxElements = () => {
        let checkboxElements = [];
        this.props.mistakeTags.forEach((mistakeTag) => {




            let currentCheckboxStatus = false;
            this.props.newMistakeTagRelations.forEach((tag) => {
                if (tag === mistakeTag.id) {
                    currentCheckboxStatus = true;
                }
            }); //key={mistakeTag.id}
            checkboxElements.push(
                <li key={mistakeTag.id}>
                    <EditMistakeTagRelationsCheckbox mistakeTag={mistakeTag}
                                                     checked={currentCheckboxStatus}
                                                     updateNewMistakeTagRelations={this.props.updateNewMistakeTagRelations}
                    />
                </li>
            );
        });
        return checkboxElements;
    };

    render() {
        return (
            <div className="mb-3">
                <b>{this.props.name}</b>
                <ol>
                    {this.getCheckboxElements()}
                </ol>
            </div>

        )
    }
}

export default withRouter(EditMistakeTagRelationsGroup);