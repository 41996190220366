/**
 * Created by jankothe on 19.06.18.
 */
import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {Navbar, Nav, NavItem} from "react-bootstrap";


class CustomNavbar extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {

    }

    feedbackClicked = (e) => {
        this.props.history.push("/feedback");
    };


    render() {
       return (
            <Navbar bg="light" variant="light">

                <Navbar.Brand href={"/chart"}>
                    Klausurio
                </Navbar.Brand>
                <Nav className="mr-auto"></Nav>
                <Nav >
                    <Nav.Link href="#" onClick={this.feedbackClicked}>
                        Feedback
                    </Nav.Link>
                    <Nav.Link href="#" onClick={this.handleLogoutClick}>
                        Logout
                    </Nav.Link>
                </Nav>
            </Navbar>
        );
    }

    handleLogoutClick = (e) => {
        this.props.onLogoutClicked(e);
    }
}


export default withRouter(CustomNavbar);