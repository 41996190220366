/**
 * Created by jankothe on 19.06.18.
 */
import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';

import '../css/LandingPage.css';

import {Button, Col, ControlLabel, FormControl, FormGroup, Form, Row, Alert} from "react-bootstrap";
import BackgroundImage from '../img/LandingPageBG.jpg';


class ResetPassword extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            requestFinished: false,
            requestStarted: false,
            success: null,
            error: null,
            passwordInputValue: '',
            repeatPasswordInputValue: '',
            readyToSubmit: false
        }
    }

    componentWillMount() {
        document.body.style.backgroundImage = `url(${BackgroundImage})`;
        document.body.style.backgroundSize = 'cover';
        document.body.style.height = '100%';
        // document.body.style.backgroundColor = "red";
    }

    componentWillUnmount() {
        //document.body.style.backgroundImage = null;
    }

    componentDidMount() {

    }

    render() {
        return (
            <div className="container-fluid fill main-container">
                <Row className={"main-row align-items-center full-row"}>
                    <Col md={5} className={"left-col"} id={"landingPageLeftCol"}>
                        <div id={"spacer"}>
                            {this.getLeftColContent()}
                        </div>
                    </Col>
                    <Col md={7} className={"right-col"} id={"landingPageRightCol"}>
                        <div id={"rightColContent"}>
                            <h1>Verbesserst du dich?</h1>
                            <h4>Lass deine Exü Ergebnisse analysieren - Steigen Deine Noten? Wie gut bist du im Schnitt?
                                Wie oft liegst du über dem Durchschnitt?</h4>
                            <h4>Deine Ergebnisse sind und bleiben natürlich nur für dich sichtbar!</h4>
                        </div>
                    </Col>
                </Row>
            </div>

        );
    }

    getLeftColContent = () => {
        //Show the form if request has not been started or the request failed
        if ((!this.state.requestStarted && !this.state.requestFinished)|| (this.state.requestFinished && this.state.success === false && this.state.error)) {
            return (
                <div>
                    <h1>Neues Passwort festlegen</h1>
                    {this.getErrorMessage()}
                    <form className={"right-col-form align-middle"}>
                        <FormGroup
                            controlId={"formPassword"}
                            validationState={this.getPasswordValidationState()}
                        >
                            <Form.Label>Passwort</Form.Label>
                            <FormControl
                                type={"password"} value={this.state.passwordInputValue}
                                placeholder={"Passwort"}
                                onChange={this.handlePasswordChange}
                            />
                            <FormControl
                                type={"password"} value={this.state.repeatPasswordInputValue}
                                placeholder={"Passwort wiederholen"}
                                onChange={this.handleRepeatPasswordChange}
                            />
                            <FormControl.Feedback/>
                            <Form.Text>Mindestens 6 Zeichen</Form.Text>
                        </FormGroup>
                        <Button bsStyle="primary" onClick={this.onFormSubmit} disabled={!this.state.readyToSubmit}>Passwort
                            jetzt ändern</Button>
                    </form>
                </div>
            )
        } else {
            if (!this.state.requestFinished) {
                return (<div>
                    <h1>Bitte warte.</h1>
                    <p>Dein neues Passwort wird gespeichert! Bitte habe einen Moment Geduld.</p>
                    <div className="loader"></div>
                </div>);
            } else if (this.state.success === true) {
                return (
                    <div id={"spacer"}>
                        <h1>Passwort geändert!</h1>
                        <p>Dein geändertes Passwort wurde gespeichert. Klicke unten um direkt zu Klausurio zu
                            gelangen.</p>
                        <Button bsStyle="primary" onClick={this.goToMainAppButtonPressed}>Weiter</Button><br/>
                    </div>);
            }
        }
    };

    goToMainAppButtonPressed = (e) => {
        this.props.history.push("/chart");
    };

    getErrorMessage = () => {
        if (this.state.error) {
            let errorMessage = "";
            switch (this.state.error) {
                case 7:
                    errorMessage = "Es ist ein interner Fehler aufgetreten. Wenn das Problem weiter besteht wende dich an " + process.env.REACT_APP_SUPPORT_EMAIL + " ich melde mich bei Dir, so schnell es geht!";
                    break;
                case 6:
                    errorMessage = "Dein neues Passwort entspricht nicht den Anforderungen an Passswörter. Ist es lang genug? Wenn das Problem weiter besteht wende dich an " + process.env.REACT_APP_SUPPORT_EMAIL + " ich melde mich bei Dir, so schnell es geht!";
                    break;
                case 5:
                    errorMessage = "Dein Link zum wiederherstellen des Passworts ist leider ungültig. Bitte geh zurück zum Login und lass Dir einen neuen zuschicken. Wenn das Problem weiter besteht wende dich an " + process.env.REACT_APP_SUPPORT_EMAIL + " ich melde mich bei Dir, so schnell es geht!";
                    break;
                default:
                    //errorMessage = "Es ist ein interner Fehler aufgetreten. Wir arbeiten an einer schnellen Lösung. Wenn das Problem weiter besteht wende dich an " + process.env.REACT_APP_SUPPORT_EMAIL + " wir melden uns bei dir, so schnell es geht!"
                    break;
            }
            return <Alert bsStyle="danger">{errorMessage}</Alert>;
        } else {
            return ""
        }
    };

    onFormSubmit = (e) => {
        let params = this.props.match.params;
        let userId = params.id;
        let resetPasswordToken = params.resetPasswordToken;

        this.props.updatePasswordAndLogin(userId, resetPasswordToken, this.state.passwordInputValue)
            .then(() => {
                this.setState({
                    requestFinished: true,
                    requestStarted: false,
                    success: true,
                    error: null
                })
            })
            .catch((error) => {
                if(!error){
                    error = 7;
                }
                this.setState({
                    requestFinished: true,
                    requestStarted: false,
                    success: false,
                    error: error
                })
            })

    };



    backButtonClicked = () => {
        this.props.history.push("/");
    };

    getPasswordValidationState = () => {
        if (this.state.passwordInputValue.length === 0) {
            return null;
        } else if (this.state.passwordInputValue.length >= 6) {
            if (this.state.repeatPasswordInputValue.length === 0) {
                return null;
            } else {
                if (this.state.passwordInputValue === this.state.repeatPasswordInputValue) {
                    return 'success';
                } else {
                    return 'error';
                }
            }
        } else {
            return 'error';
        }
    };

    handlePasswordChange = (e) => {
        this.setState({passwordInputValue: e.target.value},
            () => {
                this.refreshSubmitButton();
            });
    };

    handleRepeatPasswordChange = (e) => {
        this.setState({repeatPasswordInputValue: e.target.value},
            () => {
                this.refreshSubmitButton();
            });
    };


    refreshSubmitButton = () => {
        if (this.getPasswordValidationState() === 'success') {
            this.setState({
                readyToSubmit: true
            })
        } else {
            this.setState({
                readyToSubmit: false
            })
        }
    };


}

export default withRouter(ResetPassword);
