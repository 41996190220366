/**
 * Created by jankothe on 19.06.18.
 */
import React, {Component} from 'react';
import {Row, Col, Alert} from 'react-bootstrap';
import {Route, withRouter, Link} from 'react-router-dom';


import '../css/LandingPage.css';
import SignupForm from './SignupForm';
import LoginForm from './LoginForm';
import LoginSignupResponse from './LoginSignupResponse';
//import BackgroundImage from '../img/LandingPageBG.jpg';
//import BackgroundImage from '../img/LandingPageBgClean.jpg';
import RequestPasswordResetView from "./RequestPasswordResetView";
import BottomInformationRow from "./BottomInformationRow";
//import ReactGA from "react-ga";


class LandingPage extends Component {
    constructor(props, context) {
        super(props, context);


        this.state = {
            errorMessage: "",
            loginActive: true,
            resetPasswordActive: false,
            response: "",
            currentlyLoading: false
        };

        //If loginActive === true: Show login form, otherwise show signup form
        if (this.props.loginData.currentlyLoggedIn === true) {
            this.props.history.push("/chart");
        }
    }

    componentWillMount() {
        /*document.body.style.backgroundImage = `url(${BackgroundImage})`;
        document.body.style.backgroundSize = 'cover';
        document.body.style.height = '100%';*/
        // document.body.style.backgroundColor = "red";
    }

    componentWillUnmount() {
        //document.body.style.backgroundImage = null;
    }

    setLoadingStatus = (status) => {
        if (typeof (status) === "boolean") {
            this.setState({
                currentlyLoading: status
            })
        }
    };

    getErrorMessage = () => {
        if (this.state.errorMessage !== "") {
            return (
                <Alert variant={"danger"}>
                    {this.state.errorMessage}
                </Alert>);
        } else {
            return "";
        }
    };

    handleSignupFormSubmit = (email, password, classYear) => {
        let url = process.env.REACT_APP_API + "/user";
        this.setLoadingStatus(true);
        fetch(url, {
            method: 'post',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: email,
                password: password,
                classYear: classYear
            })
        })
            .then((resp) => resp.json())
            .then(answer => {
                this.setLoadingStatus(false);
                let result = answer;
                //I think the errorMessage Prop is unnecessary.
                if (result.success === true) {
                    this.setState({
                        response: "signupSuccessful"
                    });
                } else if (result.error === 2) {
                    this.setState({
                        response: "error",
                        errorMessage: "Diese Mailadresse wird schon benutzt. Bitte logge dich ein."
                    })
                } else if (result.error === 1) {
                    this.setState({
                        response: "error",
                        errorMessage: "Es ist ein interner Fehler (Fehlercode 1) bei der Registrierung aufgetreten. Wenn das Problem länger besteht, schreib mir an " + process.env.REACT_APP_SUPPORT_EMAIL + " ich melde mich bei dir!"
                    })
                } else {
                    this.setState({
                        response: "error",
                        errorMessage: "Bei Deiner Registrierung ist ein unbekannter Fehler aufgetreten. Wenn das Problem länger besteht, schreib mir an " + process.env.REACT_APP_SUPPORT_EMAIL + " ich melde mich bei dir!"
                    });
                }
            })
            .catch(() => {
                    this.setState({
                        response: "error",
                        errorMessage: "Bei Deiner Registrierung ist ein unbekannter Fehler aufgetreten. Wenn das Problem länger besteht, schreib mir an " + process.env.REACT_APP_SUPPORT_EMAIL + " ich melde mich bei dir!"
                    });
                }
            )
    };

    handleLoginFormSubmit = (email, password) => {
        this.props.loginMethod(email, password)
            .then(() => {
                this.props.history.push("/chart");
            })
            .catch((e) => {
                if (e === 8) {
                    this.setState({
                        response: "error",
                        errorMessage: "Leider konntest du nicht eingeloggt werden. Deine Mailadresse oder dein Passwort sind falsch."
                    });
                } else if (e === 9) {
                    this.setState({
                        response: "error",
                        errorMessage: "Leider konntest du nicht eingeloggt werden. Du musst vorher Deine Mailadresse mit dem Link, den du per Mail bekommen hast verifizieren. Wenn du keine Mail bekommen hast oder sie verloren hast, schreib mir an " + process.env.REACT_APP_SUPPORT_EMAIL + "."
                    });
                } else {
                    this.setState({
                        response: "error",
                        errorMessage: "Leider konntest du nicht eingeloggt werden. Es ist ein unbekannter Fehler aufgetreten. Wenn das Problem länger besteht, schreib mir an " + process.env.REACT_APP_SUPPORT_EMAIL + " ich melde mich bei Dir, so schnell es geht!"
                    });
                }

            })
    };

    selectLoginButtonClicked = (e) => {
        /*ReactGA.event({
            category: 'User',
            action: 'Landing page: switched to login view'
        });*/
        e.preventDefault();
        this.setState({
            loginActive: true
        });
    };

    selectSignupButtonClicked = (e) => {
        /*ReactGA.event({
            category: 'User',
            action: 'Landing page: switched to signup view'
        });*/
        e.preventDefault();
        this.setState({
            loginActive: false
        });
    };

    getLoader = () => {
        if (this.state.currentlyLoading) {
            return <div class="loader"></div>
        } else {
            return ""
        }
    };

    goBackToLoginSignupForms = () => {
        /*ReactGA.event({
            category: 'User',
            action: 'Landing page: returned from reset password view'
        });*/
        this.setState({
            response: "",
            resetPasswordActive: false
        });
    };

    resetPasswordButtonClicked = () => {
        /*ReactGA.event({
            category: 'User',
            action: 'Landing page: reset password button clicked'
        });*/
        this.setState({
            resetPasswordActive: true
        })
    };


    getLeftColContent = () => {
        if (this.state.response === "signupSuccessful") {
            return (<LoginSignupResponse response={this.state.response}
                                         goBack={this.goBackToLoginSignupForms}/>);
        } else {
            if (this.state.resetPasswordActive) {
                return (
                    <div id={"spacer"}>
                        {this.getErrorMessage()}
                        <RequestPasswordResetView goBack={this.goBackToLoginSignupForms}/>
                    </div>
                )
            } else {
                //{this.getEditButtons()}
                return (<div>

                    <div id={"spacer"}>
                        <h1>Klausurio</h1>
                        {this.getErrorMessage()}
                        <Route path="/" exact render={() => {
                            return (
                                <div className={"loginFormBorder"}>
                                    <LoginForm onSubmit={this.handleLoginFormSubmit} getLoader={this.getLoader}
                                               resetPasswordButtonClicked={this.resetPasswordButtonClicked}/>
                                    <hr/>
                                    <Link to={"/signup"}>Noch nicht angemeldet? Hier registrieren!</Link>
                                </div>
                            )
                        }}/>

                        <Route path="/signup" render={() => {
                            return (
                                <div>
                                    <SignupForm onSubmit={this.handleSignupFormSubmit} getLoader={this.getLoader}/>
                                    <hr/>
                                    <Link to={"/login"}>Schon angemeldet? Hier einloggen!</Link>
                                </div>
                            )
                        }}/>

                        <Route path="/login" render={() => {
                            return (
                                <div>
                                    <LoginForm onSubmit={this.handleLoginFormSubmit} getLoader={this.getLoader}
                                               resetPasswordButtonClicked={this.resetPasswordButtonClicked}/>
                                    <hr/>
                                    <Link to={"/signup"}>Noch nicht angemeldet? Hier registrieren!</Link>
                                </div>
                            );
                        }
                        }/>


                    </div>
                </div>);
            }
        }
    };

    render() {
        return (
            <div>
                <div id={"bgImage"}>
                </div>
                <div id={"bgColorOverlay"}>
                </div>

                <div className="container-fluid fill main-container">
                    <Row className={"main-row align-items-center full-row"}>

                        <Col md={5} className={"left-col"} id={"landingPageLeftCol"}>
                            {this.getLeftColContent()}
                            <BottomInformationRow/>
                        </Col>
                        <Col md={7} className={"right-col"} id={"landingPageRightCol"}>
                            <div id={"rightColContent"}>
                                <h1>Verbesserst Du Dich?</h1>
                                <h4>Lass Deine Exü Ergebnisse analysieren - steigen Deine Noten? Wie gut bist Du im
                                    Schnitt?
                                    Wie
                                    oft liegst Du über dem Durchschnitt?</h4>
                                <h4>Deine Ergebnisse sind und bleiben natürlich nur für dich sichtbar!</h4>
                            </div>
                        </Col>
                    </Row>
                </div>

            </div>
        );
    }
}

export default withRouter(LandingPage);


/*<Grid fluid>
                    <Row className={"align-items-center full-row"}>
                        <Col md={4} className={"left-col"}>
                            <Carousel>
                                <Carousel.Item>
                                    <img className={"carousel-image"} src={carouselImage} alt={""}/>
                                    <Carousel.Caption>
                                        <h3>Verbesserst du dich?</h3>
                                        <p>Verfolge genau, ob du dich, in deinen Klausurergebnissen im Schnitt
                                            verbesserst</p>
                                    </Carousel.Caption>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img className={"carousel-image"} src={carouselImage} alt={""}/>
                                    <Carousel.Caption>
                                        <h3>Bist du bereit fürs Examen?</h3>
                                        <p>Erkenne anhand verschiedener Messwerte, ob du dich schon für das Examen
                                            melden
                                            solltest</p>
                                    </Carousel.Caption>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img className={"carousel-image"} src={carouselImage} alt={""}/>
                                    <Carousel.Caption>
                                        <h3>Bleibt unter uns!</h3>
                                        <p>Deine Ergebnisse bleiben geheim und können nicht von anderen gesehen
                                            werden</p>
                                    </Carousel.Caption>
                                </Carousel.Item>
                            </Carousel>
                        </Col>
                        <Col md={4} className={"right-col"}>
                            <SignupForm onSubmit={this.handleSignupFormSubmit}/>
                        </Col>
                    </Row>
                </Grid>
                */