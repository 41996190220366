/**
 * Created by jankothe on 19.06.18.
 */
import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {Button} from "react-bootstrap";



class MainDataTableRow extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {

    }

    render() {
        /*<tr>

                <td>{this.props.no}</td>
                <td>{dateString}</td>
                <td>{this.props.subject}</td>
                <td>{this.props.grade}</td>
                <td>{this.props.averageGrade}</td>
            </tr>*/


        let date = new Date(this.props.exu.date);
        let dateString = date.toLocaleDateString();

        let grade = "-";
        if(this.props.exam){
            grade = this.props.exam.grade;
        }

        //Structure: No, Datum, Fach, Note, Jahrgangsschnitt
        return (
            <tr>
                <td>{this.props.no}</td>
                <td>{dateString}</td>
                <td>{this.props.exu.subject}</td>
                <td>{this.props.exu.averageGrade}</td>
                <td>{grade}</td>
                <td>{this.getButton()}</td>
            </tr>
        )
    }

    getButton = () =>{
        let buttonText = "Hinzufügen";
        if(this.props.exam){
            buttonText = "Bearbeiten";
        }
        return (<Button variant={"secondary"} onClick={this.handleEditButtonClick}>{buttonText}</Button>);
    };

    handleEditButtonClick = (e) => {
        this.props.handleEditButtonClick(this.props.exu.id);
    }
}


export default withRouter(MainDataTableRow);